import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommentableAoiDTO,
  CommentableAoiPayload,
} from '@transect-nx/data-transfer-objects';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommentableAoiApiService {
  private baseURL = `${environment.apiUrl}/commentable-aoi`;

  constructor(private http: HttpClient) {}

  postCommentableAoi(
    commentableAoiPayload: CommentableAoiPayload,
  ): Observable<CommentableAoiDTO> {
    return this.http.post<CommentableAoiDTO>(
      this.baseURL,
      commentableAoiPayload,
    );
  }

  getCommentableAoisByProjectId(
    projectId: string,
  ): Observable<CommentableAoiDTO[]> {
    return this.http.get<CommentableAoiDTO[]>(this.baseURL, {
      params: {
        project__id: projectId,
      },
    });
  }

  deleteCommentableAoi(commentableAoiId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseURL}/${commentableAoiId}`);
  }
}

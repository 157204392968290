import { z } from 'zod';
import { CommentObjectType } from '@transect-nx/models';

export const CommentObjectLatestViewsPayload = z.object({
  object__type: z.nativeEnum(CommentObjectType),
  object__id: z.string().uuid(),
});
export type CommentObjectLatestViewsPayload = z.infer<
  typeof CommentObjectLatestViewsPayload
>;

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { GeographyDTO } from '../geography';
import { RegulationDisplayCriteria } from '@transect-nx/models';

export const RegulationDTO = z.object({
  _id: z.string().uuid(),
  customer__id: z.string().uuid().nullish(),
  parent__id: z.string().uuid().nullish(),
  descriptor: z.string().nullable().default(''),
  name: z.string().nullable().default(''),
  acronym: z.string().nullable().default(''),
  nameAcronymBack: z.string().nullable().default(''),
  jurisdiction: z.string().nullish(),
  topic: z.string().nullish(),
  child_type: z.string().nullish(),
  regulation_location_trigger: z.boolean().default(false),
  requires_impact_to_waters: z.boolean().default(false),
  content: z
    .object({
      intro: z.string().nullish(),
      body: z.string().nullish(),
      recommendation: z.string().nullish(),
      implications: z.string().nullish(),
    })
    .default({})
    .nullish(),
  status_expert: z.preprocess(
    (val) => val ?? 'in_progress',
    z.string().default('in_progress'),
  ),
  data_gap: z.boolean().default(false),
  active: z.boolean().default(true),
  assigned_to: z.string().uuid().nullish(),
  triggers: z.array(z.any()).default([]),
  refresh_threshold: z.number().default(90),
  last_refresh_date: DateSchema.nullish(),
  region: z.string().nullish(),
  owner__id: z.string().uuid().nullish(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullish(),
  review_by: DateSchema.nullish(),
  order: z.number().default(0),
  display_only_with_children: z.boolean().default(false),
  display_criteria: z
    .nativeEnum(RegulationDisplayCriteria)
    .default(RegulationDisplayCriteria.JURISDICTION),
  links: z
    .array(
      z.object({
        name: z.string(),
        url: z.string().url(),
      }),
    )
    .default([]),
  geography__id: z.string().uuid().nullish(),
  geography: GeographyDTO,
  regulator: z.object({}).passthrough(),
  regulator__id: z.string().uuid().nullish(),
  regulation_locations: z.array(z.any()),
});
export type RegulationDTO = z.infer<typeof RegulationDTO>;

/**
 * The type of the notification. This will determine icon style.
 */
export enum NotificationType {
  SHARING = 'sharing',
  COMPLETION = 'completion',
  REFRESH = 'refresh',
  TIMEOUT = 'timeout',
  NEWS = 'news',
  WARN = 'warn',
  MONITOR = 'monitor',
}

/**
 * The type of the entity to be notified.
 */
export enum NotificationNotifiableType {
  USER = 'user',
  CUSTOMER = 'customer',
  PUBLIC = 'public',
}

/**
 *  The type of the entity to be notified.
 */
export enum NotificationEntityType {
  EXPORT = 'export',
  REPORT = 'report',
  ADD_ON = 'add_on',
  EXPERT_REVIEW = 'expert_review',
  SITE_SELECTION = 'site_selection',
  PROJECT = 'project',
  NDA_GENERATED = 'nda_generated',
  PROJECT_SHARED = 'project_shared',
  EXTERNAL_RESOURCE = 'external_resource',
  PARCEL_FILTER_EXPORT = 'parcel_filter_export',
  COMMENT = 'comment',
  MORATORIUM_VERIFIED = 'moratorium_verified',
  MORATORIUM_INVALIDATED = 'moratorium_invalidated',
  ADDON_COMMENT = 'addon_comment',
  ADD_ON_DUE_DATE_CHANGED = 'add_on_due_date_changed',
  LANDOWNER_CONTACT_ORDER_SUCCESSFUL = 'landowner_contact_order_successful',
  LANDOWNER_CONTACT_ORDER_FAILED = 'landowner_contact_order_failed',
  LANDOWNER_CONTACT_ORDER_EXPORT = 'landowner_contact_order_export',
  PARCEL_COLLECTION_GENERATOR = 'parcel_collection_generator',
}

import { z } from 'zod';
import { ReportAddOnOfferingKey } from '../models';
import { DateSchema } from '../utils/date-schema';
import {
  NotificationEntityType,
  NotificationNotifiableType,
  NotificationType,
} from '@transect-nx/models';

export enum NotificationIcon {
  CLIPBOARD = 'clipboard',
  SHARE = 'share',
  BINOCULARS = 'binoculars',
  SPARKLES = 'sparkles',
}

export const NotificationPayloadDTO = z.object({
  _id: z.optional(z.string().uuid()),
  user_id: z.string().uuid().optional(),
  title: z.string(),
  description: z.string(),
  meta: z.optional(
    z
      .object({
        site_selection_request_id: z.optional(z.string().uuid()),
        report_id: z.optional(z.string().uuid()),
        project_id: z.optional(z.string().uuid()),
        report__id: z.optional(z.string().uuid()),
        add_on_id: z.optional(z.string().uuid()),
        download: z.optional(z.string()).nullable(),
        icon: z.optional(z.nativeEnum(NotificationIcon)),
        link: z.optional(z.string()),
        add_on_offering_key: z.optional(ReportAddOnOfferingKey),
        subTitle: z.string().nullish(),
        open_hubspot: z.optional(z.boolean()).nullable(),
        matchedCount: z.number().optional(),
        unMatchedCount: z.number().optional(),
        reportExportSectionName: z.string().optional(),
        parcel_collection_type: z.string().optional(),
        object__id: z.string().uuid().optional(),
      })
      .passthrough(),
  ),
  entity_type: z.optional(z.nativeEnum(NotificationEntityType)),
  entity__id: z.string().uuid().optional().nullable(),
  type: z.nativeEnum(NotificationType),
  notifiable__id: z.string().uuid().optional().nullable(),
  notifiable_type: z.nativeEnum(NotificationNotifiableType),
  updated_at: DateSchema.optional(),
  created_at: DateSchema.optional(),
  deleted_at: DateSchema.nullable().optional(),
  seen: z.boolean().optional(),
});

export type NotificationPayloadDTO = z.infer<typeof NotificationPayloadDTO>;

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ProjectStatus } from '@transect-nx/models';

export const SelectionInfoResponseBase = z.object({
  _id: z.string(),
  count: z.number(),
  owners: z.number(),
  boundary_area: z.number(),
  total_acreage: z.number(),
  nearest_substation: z
    .object({
      distance: z.number(),
      max_volt: z.number().nullish(),
      min_volt: z.number().nullish(),
    })
    .nullish(),
  nearest_transmission_line: z
    .object({
      owner: z.string().nullish(),
      voltage: z.number().nullish(),
      distance: z.number(),
    })
    .nullish(),
  low_count: z.number(),
  med_count: z.number(),
  high_count: z.number(),
  undetermined_count: z.number(),
});

export const ParcelFilterSelectionInfoResponse =
  SelectionInfoResponseBase.extend({
    type: z.literal('parcel-filter'),
    total_buildable_acreage: z.number(),
    name: z.string().optional(),
  });

export const ProjectSelectionInfoResponse = SelectionInfoResponseBase.extend({
  type: z.literal('project'),
  created_at: DateSchema,
  creator: z.string(),
  name: z.string().optional(),
  status: z.nativeEnum(ProjectStatus),
  project_role: z.string(),
});

export const SiteSelectionInfoResponseDTO = z
  .discriminatedUnion('type', [
    ParcelFilterSelectionInfoResponse,
    ProjectSelectionInfoResponse,
  ])
  .nullable();

export type ProjectSelectionInfoResponse = z.infer<
  typeof ProjectSelectionInfoResponse
>;

export type ParcelFilterSelectionInfoResponse = z.infer<
  typeof ParcelFilterSelectionInfoResponse
>;

export type SiteSelectionInfoResponseDTO = z.infer<
  typeof SiteSelectionInfoResponseDTO
>;

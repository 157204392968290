import { Feature, LineString, Point } from '@turf/turf';

export const FLOOD_PLAINS_ONE_HUNDRED_YEAR = '100 year';
export const FLOOD_PLAINS_FIVE_HUNDRED_YEAR = '500 year';

export type TransectAPIErrorCodes =
  | 'ERR_FREE_USER_PROJECT_CREATION_LIMIT'
  | 'PROJECT_MONITOR_LIMIT_REACHED';

export enum ElectricGeneratorDataCategory {
  PLANNED = 'planned',
  OPERATING = 'operating',
  CANCELED_POSTPONED = 'canceled_postponed',
}

export enum ElectricGeneratorDataTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum AssessmentLevels {
  IGNORE = 'ignore',
  SPECIES_OF_CONCERN = 'species_of_concern',
  MAY_OCCUR = 'may_occur',
  NOT_LIKELY_TO_OCCUR = 'not_likely_to_occur',
  LIKELY_TO_OCCUR = 'likely_to_occur',
  NONE = 'none',
}

export enum PulseTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum PulseNewsTagsName {
  SOLAR = 'solar',
  WIND = 'wind',
}

export enum PulseRegulationsTechnology {
  SOLAR = 'solar',
  WIND = 'wind',
}

export const PulseTechnologyToPulseNewsTag: Record<
  PulseTechnology,
  PulseNewsTagsName
> = {
  [PulseTechnology.SOLAR]: PulseNewsTagsName.SOLAR,
  [PulseTechnology.WIND]: PulseNewsTagsName.WIND,
};

export const PulseNewsTagToPulseTechnology: Record<
  PulseNewsTagsName,
  PulseTechnology
> = {
  [PulseNewsTagsName.SOLAR]: PulseTechnology.SOLAR,
  [PulseNewsTagsName.WIND]: PulseTechnology.WIND,
};

export const PulseTechnologyToElectricData: Record<
  PulseTechnology,
  ElectricGeneratorDataTechnology
> = {
  [PulseTechnology.SOLAR]: ElectricGeneratorDataTechnology.SOLAR,
  [PulseTechnology.WIND]: ElectricGeneratorDataTechnology.WIND,
};

export const ElectricDataToPulseTechnology: Record<
  ElectricGeneratorDataTechnology,
  PulseTechnology
> = {
  [ElectricGeneratorDataTechnology.SOLAR]: PulseTechnology.SOLAR,
  [ElectricGeneratorDataTechnology.WIND]: PulseTechnology.WIND,
};

export const PulseTechnologyToPulseRegulationsTechnology: Record<
  PulseTechnology,
  PulseRegulationsTechnology
> = {
  [PulseTechnology.SOLAR]: PulseRegulationsTechnology.SOLAR,
  [PulseTechnology.WIND]: PulseRegulationsTechnology.WIND,
};

export type LinearData =
  | {
      trigger_type: 'spatial';
      intersections:
        | (
            | {
                intersection_type: 'segment';
                data: {
                  start: { milestone: number };
                  end: { milestone: number };
                  line: LineString;
                };
              }
            | {
                intersection_type: 'point';
                data: { point: Point; milestone: number };
              }
          )[];
    }
  | {
      trigger_type: 'non_spatial';
      intersections: undefined;
    };

export type LinearDataParams = {
  aoi_intersection_start_point: string | null;
  aoi_intersection_end_point: string | null;
  aoi_intersection_start_point_distance_meters: number;
  aoi_intersection_end_point_distance_meters: number;
  geometry: string;
};

export type TransectSummary =
  | TransectSummaryAOI
  | TransectSummaryBuffer
  | TransectSummaryExternal
  | TransectSummaryUndefinedLocation
  | TransectSummaryAnonymized;

export interface TransectSummaryAOI {
  location: 'aoi';
  area: {
    meters: number;
    feet: number;
    miles: number;
    acres: number;
  };
  length: {
    miles: number;
    feet: number;
  };
  center: Feature<Point> | null;
  linear_data?: LinearData;
}

export interface TransectSummaryBuffer {
  location: 'buffer';
  area: {
    meters: number;
    feet: number;
    miles: number;
    acres: number;
  };
  length: {
    miles: number;
    feet: number;
  };
  center: Feature<Point> | null;
}

export interface TransectSummaryUndefinedLocation {
  location: undefined;
  linear_data?: LinearData;
}

export interface TransectSummaryExternal {
  location: 'external';
  isExternal: boolean;
  bearing: number;
  distance: {
    miles: number;
    feet: number;
  };
}

export interface TransectSummaryAnonymized {
  location: 'anonymized';
  isExternal: boolean;
  center: Feature<Point> | null;
}

export enum CriticalFailureTypes {
  REPORT_GENERATION = 'Report Generation',
  REPORT_EXPORT = 'Report Export',
  BUILDABLE_AREA_CREATION = 'Buildable Area Creation',
  PDF_GENERATION = 'PDF Generation',
  KML_GENERATION = 'KML Generation',
  REPORT_PART = 'Report Part',
}

export enum AtlassianServiceDesk {
  DataRequests = '1',
}

export enum AtlassianRequestType {
  CustomerDataRequest = '4',
}

export enum AtlassianField {
  Customer = 'customfield_10031',
  Styling = 'customfield_10066',
}

export enum HubspotEmailId {
  WATER_FIDGET_EXPORT_COMPLETE = 69279819608,
}

export enum CreatedFrom {
  REPORT = 'report',
  DUPLICATE_REPORT = 'duplicate-report',
  NA = 'NA',
  PROJECTS = 'projects',
  SS_SINGLE_EXPORT = 'ss_single_export',
  VISION = 'vision',
  PARCEL_FILTER = 'parcel_filter',
  BULK_PROJECTS = 'bulk_projects',
  MINI_REPORT = 'mini-report',
}

export enum TransectPlanKey {
  NoAccess = 'no-access',
  Free = 'free',
  Pro = 'pro',
  AssessOnly = 'assess_only',
  SelfServeFreeTrial = 'self_serve_free_trial',
}

export enum FormTypeKey {
  SITE_ACCESS_AUTHORIZATION = 'form_marketplace_site_access_authorization',
  USER_QUESTIONNAIRE = 'form_marketplace_user_questionnaire',
  MARKETPLACE_AGREEMENT = 'marketplace_agreement',
  ALTA_SCOPE_OF_WORK = 'form_marketplace_alta_scope_of_work',
  PHASE1_ESA_AMENDMENT = 'form_marketplace_phase1esa_amendment',
  TESS_SCOPE_OF_WORK = 'form_marketplace_tess_scope_of_work',
  FREE_TRIAL_AGREEMENT = 'form_user_free_trial_agreement',
  LANDOWNER_CONTACT_TOC = 'form_landowner_contact_toc',
  CEII_NDA = 'form_ceii_nda',
}

export enum ReportAddOnOfferingKey {
  pzr = 'pzr',
  slope = 'slope',
  archaeology = 'archaeology',
  local_rrs = 'local_rrs',
  phase1_esa = 'phase1_esa',
  tess = 'tess',
  wetland_dln = 'wetland_dln',
  alta = 'alta',
  interconnection_study = 'interconnection_study',
}

export enum TimelineUnit {
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export enum ProjectStatus {
  UNDEFINED = 'undefined',
  APPROVED = 'approved',
  NEEDS_REVIEW = 'needs_review',
  IN_PROGRESS = 'in_progress',
  BLOCKED = 'blocked',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
}

export enum ReportExportFileType {
  PDF = 'pdf',
  KML = 'kml',
  SHP = 'shp',
  DXF = 'dxf',
  ZIP = 'zip',
  XLSX = 'xlsx',
}

export enum ReportExportType {
  PDF = 'pdf',
  KML = 'kml',
  TERABASE = 'kml-tb',
  WATERS_SAMPLE = 'pdf-wr',
  MINI_REPORT = 'pdf-mr',
  LINEAR_DATA = 'linear-data',
}

export enum CommentObjectType {
  SPECIES = 'species',
  REFERENCES = 'references',
  PERMITS = 'permits',
  REPORTS = 'reports',
  REGULATORS = 'regulators',
  PROJECTS = 'projects',
  VISION_SNAPSHOTS = 'vision-snapshots',
  REGULATIONS = 'regulations',
  CATALOG_ITEMS = 'catalog_items',
  REPORT_ADD_ONS = 'report_add_ons',
  SOLAR_REGULATIONS = 'solar_regulations',
  REPORT_SECTION_NOTES = 'report_section_notes',
  COMMENTABLE_AOI = 'commentable_aoi',
}

export enum ReportSectionKeys {
  EXECUTIVE_SUMMARY = 'executive_summary',
  PERMIT_MATRIX = 'permit_matrix',
  SPECIES_FEDERAL_PROFILES = 'species_federal_profiles',
  SPECIES_STATE_PROFILES = 'species_state_profiles',
  WATERS = 'waters',
  PROTECTED_AREAS = 'protected_areas',
  ENVIRONMENTAL_COMPLIANCE = 'environmental_compliance',
  SETTING_SOILS = 'setting_soils',
  SETTING_PARCELS = 'setting_parcels',
  INTERCONNECTION = 'interconnection',
  LANDOWNERS = 'landowners',
}

export enum FederalListingStatus {
  CANDIDATE = 'Candidate',
  ENDANGERED = 'Endangered',
  EXPERIMENTAL_POPULATION_NON_ESSENTIAL = 'Experimental Population, Non-Essential',
  EXTINCTION = 'Extinction',
  NOT_LISTED = 'Not Listed',
  ORIGINAL_DATA_IN_ERROR_ACT_AMENDMENT = 'Original Data in Error - Act Amendment',
  ORIGINAL_DATA_IN_ERROR_ERRONEOUS_DATA = 'Original Data in Error - Erroneous Data',
  ORIGINAL_DATA_IN_ERROR_NEW_INFO_DISCOVERED = 'Original Data in Error - New Information Discovered',
  ORIGINAL_DATA_IN_ERROR_NOT_LISTABLE_ENTITY = 'Original Data in Error - Not a listable entity',
  ORIGINAL_DATA_IN_ERROR_TAXONOMIC_REVISION = 'Original Data in Error - Taxonomic Revision',
  PRE_ACT_DELISTING_OR_CLEARANCE_REMOVAL = 'Pre-Act Delisting (or clearance--removal from the Lists)',
  PROPOSED_ENDANGERED = 'Proposed Endangered',
  PROPOSED_EXPERIMENTAL_POPULATION_NON_ESSENTIAL = 'Proposed Experimental Population, Non-Essential',
  PROPOSED_SIMILARITY_OF_APPEARANCE_TO_A_THREATENED_TAXON = 'Proposed Similarity of Appearance to a Threatened Taxon',
  PROPOSED_THREATENED = 'Proposed Threatened',
  RECOVERY = 'Recovery',
  RESOLVED_TAXON = 'Resolved Taxon',
  SIMILARITY_OF_APPEARANCE_TO_A_THREATENED_TAXON = 'Similarity of Appearance to a Threatened Taxon',
  SPECIES_OF_CONCERN = 'Species of Concern',
  STATUS_UNDEFINED = 'Status Undefined',
  THREATENED = 'Threatened',
  UNDER_REVIEW = 'Under Review',
  UNDER_REVIEW_IN_THE_CANDIDATE_OR_PETITION_PROCESS = 'Under Review in the Candidate or Petition Process',
}

export enum CustomMapLayersUploadAccess {
  ON = 'turn_on_access',
  OFF = 'turn_off_access',
}

export enum CustomerMapAccess {
  ON = 'turn_on_access',
  OFF = 'turn_off_access',
}

export enum SolarRegulationType {
  ORDINANCE = 'Ordinance',
  MORATORIUM = 'Moratorium',
}

export enum VerificationStatus {
  UNVERIFIED = 'Unverified',
  VERIFIED = 'Verified',
  VERIFICATION_REQUESTED = 'Verification Requested',
  REVIEWED_AND_REJECTED = 'Reviewed and Rejected',
  AWAITING_INITIAL_REVIEW = 'Awaiting Initial Review',
  EXPIRED = 'Expired',
}

export enum Purpose {
  STAFF_GEOSPATIAL_IMPORT = 'staff-geospatial-import',
  COMPANY_LOGO = 'company-logo',
  STAFF_REFERENCE_IMPORT = 'staff-reference-import',
  REPORT_ADD_ON_DELIVERABLE = 'report-add-on-deliverable',
  PROJECT_IMPORT = 'project-import',
  USER_CATALOG_ITEMS = 'user-catalog-items',
  SPECIES_PROFILE_IMAGE = 'species-profile-image',
  PROFILE_PHOTO = 'profile-photo',
  TRANSECT_EXPORT = 'transect-export',
  FORM = 'form',
  SOLAR_REGULATION = 'solar-regulation',
  CUSTOMER_FILE = 'customer-file',
}

export enum LandownerContactExportType {
  ALL = 'all',
  SINGLE = 'single',
  REPORT = 'report',
}

export enum LandownerContactOrderStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  API_ERROR = 'api_error',
}

export enum CatalogItemRegion {
  MIDWEST = 'midwest',
  NORTHEAST = 'northeast',
  SOUTHEAST = 'southeast',
  MOUNTAIN = 'mountain',
  SOUTH_CENTRAL = 'south_central',
  WEST = 'west',
}

export enum CatalogItemStatusExpert {
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  IN_REVIEW = 'in_review',
  REVIEW_COMPLETE = 'review_complete',
  COMPLETE = 'complete',
}

export enum LandownerContactExportStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export enum RegulationDisplayCriteria {
  CHILD = 'child',
  JURISDICTION = 'jurisdiction',
  TRIGGER = 'trigger',
  LOCATION = 'location',
}

export enum ReportAnnotationCreationType {
  USER = 'user',
  ADMIN = 'admin',
  AUTOMATED = 'automated',
}

export enum ReportPartStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error',
  TRIGGERS_ASSESSED = 'triggersAssessed',
  RESET = 'reset',
  PENDING_REGENERATION = 'pending-regeneration',
  TIMEOUT = 'timeout',
}

export enum SpeciesType {
  V = 'V',
  P = 'P',
  I = 'I',
}

export enum HabitatCharacteristics {
  CAVES_KARST = 'caves_karst',
  MOUNTAINS = 'mountains',
  FOREST_MIXED = 'forest_mixed',
  OPEN_OCEAN = 'open_ocean',
  CANYONLAND = 'canyonland',
  WETLAND_WOODY = 'wetland_woody',
  SPRINGS = 'springs',
  CROPS = 'crops',
  BEACH_DUNE = 'beach_dune',
  PONDS = 'ponds',
  COASTAL_PRAIRIE = 'coastal_prairie',
  SCRUB_SHRUB = 'scrub_shrub',
  WETLAND_FRESHWATER_EMERGENT = 'wetland_freshwater_emergent',
  LAKES = 'lakes',
  VERNAL_POOL = 'vernal_pool',
  FOREST_DECIDUOUS = 'forest_deciduous',
  MAJOR_RIVER = 'major_river',
  DESERT_SCRUB = 'desert_scrub',
  EASEMENT_ROADSIDE = 'easement_roadside',
  NATIVE_PRAIRIE = 'native_prairie',
  GEOLOGY = 'geology',
  BAY_ESTUARY = 'bay_estuary',
  PRAIRIE_POTHOLE = 'prairie_pothole',
  RANGELAND = 'rangeland',
  PEAK_CLIFFS = 'peaks_cliffs',
  FOOTHILLS = 'foothills',
  SLOPES = 'slopes',
  CANDIDATE = 'Candidate',
  FOREST_EVERGREEN = 'forest_evergreen',
  CREEK_STREAM = 'creek_stream',
  DISTRIBUTED_AREAS = 'disturbed_areas',
  ALPINE_MEADOW = 'alpine_meadow',
  DESERT_SAND_DUNES = 'desert_sand_dunes',
  GRASSLAND = 'grassland',
  DESERT = 'desert',
  FLOODPLAINS = 'floodplains',
  TIDAL_WETLAND = 'tidal_wetland',
  RIPARIAN_WOODLAND = 'riparian_woodland',
  VALLEYS = 'valleys',
}

export enum ReportPartType {
  PARCEL = 'parcel',
  TRANSPORTATION = 'transportation',
  GEOGRAPHY = 'geography',
  SPECIES_LOCATION = 'species_location',
  STATE_SPECIES_LOCATION = 'state_species_location',
  HYDROGRAPHY = 'hydrography',
  SOIL = 'soil',
  CATALOG_LOCATION = 'catalog_location',
  REGULATION_LOCATION = 'regulation_location',
  GEOLOGY = 'geology',
  FIRM_PANEL = 'firm_panel',
  FLOOD_HAZARD = 'flood_hazard',
  ECOREGION = 'ecoregion',
  PROTECTED_AREA = 'protected_area',
  CONTOUR = 'contour',
  ENVIRONMENTAL_COMPLIANCE = 'environmental_compliance',
  WETLAND = 'wetland',
  SLOPE = 'slope',
  SLOPE2 = 'slope2',
  LAND_COVER = 'landcover',
  WATERSHED = 'watershed',
  ASPECT = 'aspect',
  INTERCONNECTION_DATA = 'interconnection_data',
}

export enum ProjectsScope {
  COMPANY_PROJECTS = 'company-projects',
  MY_PROJECTS = 'my-projects',
  ALL = 'all',
  ARCHIVED = 'archived',
}
export class GeographiesState {
  state!: string;
  abbreviation!: string;
  geography__id!: string;
}

export type TransectFileGCS = {
  url?: string | null;
  file?: string | null;
  bucket?: string | null;
};

export type TransectFile = {
  _id?: string | null;
  created_at?: Date | null;
  updated_at?: Date | null;
  deleted_at?: Date | null;
  name: string;
  type: string;
  description?: string | null;
  status: string;
  properties?: {
    size?: number | null;
    purpose?: string | null;
    mimetype?: string | null;
    originalname?: string | null;
  } | null;
  gcs: TransectFileGCS;
  relations?: any | null;
  s3?: Record<string, unknown> | null; // or a more specific type if you know it
  creator__id?: string | null;
  reference__id?: string | null;
  signedUploadUrl?: string | null;
};

import { z } from 'zod';
import { GeometrySchema } from '../../utils/geometry-object-schema';

export const CommentableAoiDTO = z.object({
  _id: z.string().uuid(),
  geometry: GeometrySchema,
});

export type CommentableAoiDTO = z.infer<typeof CommentableAoiDTO>;

export const CommentableAoiPayload = z.object({
  project__id: z.string().uuid(),
  geometry: GeometrySchema,
});

export type CommentableAoiPayload = z.infer<typeof CommentableAoiPayload>;

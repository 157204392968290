<div
  class="chat-panel"
  *ngIf="(isLoadingComments$ | async) === false; else skeletonLoading"
>
  <div
    *ngIf="(commentsList$ | async)?.length > 0; else noCommentSection"
    class="comments-section"
    #commentsSection
  >
    <div *ngFor="let comment of commentsList$ | async" class="comments">
      <div
        class="comment"
        [class.clickable]="!!comment.commentableAoi"
        [class.highlighted]="comment.highlighted"
        (click)="!!comment.commentableAoi && onCommentClick(comment)"
      >
        <div class="comment-header">
          <div>
            <ts-user-profile-photo
              [transectFile]="comment.creator?.photo_gcs"
              imgClass="comments-image"
            ></ts-user-profile-photo>
          </div>
          <div class="title-holder">
            <div class="left-panel">
              <div class="label-large text-brand-colors-brand-primary-dark">
                {{ comment.creator?.fullname }}
              </div>
              <div class="label-small">
                {{ comment.creator?.customer_name ?? '' }}
              </div>
            </div>
            <div class="right-panel">
              <div *ngIf="userOrNull$ | async as user">
                <button
                  *ngIf="
                    !readonly &&
                    (user?._id === comment.creator._id ||
                      user?.role === 'admin')
                  "
                  transect-nx-button
                  buttonStyle="flat"
                  aria-label="Open Ellipsis Menu"
                  [size]="'xxs'"
                  [menuTemplate]="actionsMenu"
                  (click)="$event.stopPropagation()"
                >
                  <i class="fa-light fa-ellipsis-v"></i>
                </button>
                <ng-template #actionsMenu>
                  <ul transect-nx-menu>
                    <li
                      transect-nx-menu-item
                      (click)="$event.stopPropagation(); editComment(comment)"
                    >
                      Edit
                    </li>
                    <li
                      transect-nx-menu-item
                      (click)="$event.stopPropagation(); deleteComment(comment)"
                    >
                      Delete
                    </li>
                  </ul>
                </ng-template>
              </div>
              <div class="body-small">
                {{ getFromNow(comment.created_at) }}
              </div>
            </div>
          </div>
        </div>
        <div class="comment-textarea-container">
          <div *ngIf="!comment.isEditing" class="body-small">
            <span *ngIf="comment.readMore">
              {{ comment.properties?.text }}
            </span>
            <span *ngIf="!comment.readMore">{{
              comment.properties?.text | slice : 0 : defaultLength
            }}</span>
            <span *ngIf="comment?.properties?.text?.length > defaultLength">
              <span *ngIf="!comment.readMore">... </span>
              <a
                *ngIf="!comment.readMore"
                (click)="$event.stopPropagation(); comment.readMore = true"
                >[more...]</a
              >
              <a
                *ngIf="comment.readMore"
                (click)="$event.stopPropagation(); comment.readMore = false"
                >[less...]</a
              >
            </span>
          </div>
          <textarea
            *ngIf="comment.isEditing"
            class="body-small"
            [(ngModel)]="comment.updatedComment"
            [disabled]="comment.updatingComment === true"
            type="text"
          ></textarea>
        </div>
        <ng-container *ngIf="userOrNull$ | async as user">
          <div
            *ngIf="
              !readonly &&
              (user?._id === comment.creator._id || user?.role === 'admin')
            "
          >
            <mat-spinner
              *ngIf="comment.updatingComment || comment.deletingComment"
              [diameter]="20"
              [strokeWidth]="2"
            ></mat-spinner>
            <div
              class="actions"
              *ngIf="!comment.updatingComment && !comment.deletingComment"
            >
              <button
                *ngIf="comment.isEditing"
                transect-nx-button
                buttonStyle="contained"
                (click)="$event.stopPropagation(); updateComment(comment)"
              >
                Update
              </button>
              <button
                *ngIf="comment.isEditing"
                transect-nx-button
                buttonStyle="outlined"
                (click)="$event.stopPropagation(); cancelEditComment(comment)"
              >
                Cancel
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="comment.subTitleText">
        <div class="label-small text-shades-gray-light">
          {{ comment.subTitleText }}
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="commentTemplateButtonsTemplate"
      [ngTemplateOutlet]="commentTemplateButtonsTemplate"
    ></ng-container>
  </div>
  <div *ngIf="!readonly && showCommentCreation" class="post-comment">
    <div class="input-with-button">
      <textarea
        #commentInput
        class="body-large text-shades-gray-dark comment-textarea"
        placeholder="Post a comment..."
        [(ngModel)]="currentCommentText"
        [disabled]="(isSavingComment$ | async) === true"
        type="text"
        (keydown.enter)="handleEnterClickOnComment($event)"
        cdkTextareaAutosize="true"
        [cdkAutosizeMaxRows]="5"
      >
      </textarea>
      <mat-divider [vertical]="true"></mat-divider>
      <mat-spinner
        *ngIf="isSavingComment$ | async"
        [diameter]="18"
        [strokeWidth]="2"
      ></mat-spinner>
      <button
        transect-nx-button
        [iconOnly]="true"
        size="xxs"
        class="btn-send"
        *ngIf="(isSavingComment$ | async) === false"
        [disabled]="!currentCommentText"
        (click)="saveComment()"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-template #skeletonLoading>
  <div class="loading-container">
    <ts-spinner class="spinner"></ts-spinner>
  </div>
</ng-template>
<ng-template #noCommentSection>
  <ng-container
    *ngIf="
      commentableAois && commentableAois.length === 0;
      else defaultNoComments
    "
  >
    <div class="no-comments-aoi-container">
      <i class="fal fa-bold fa-4x fa-comment-lines"></i>
      <div>
        <div class="label-large">Hover over the map!</div>
        <div class="subtitle-no-comment body-small">
          Hover to drop or draw your first comment anywhere.
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #defaultNoComments>
    <div class="no-comments">There are no comments at this time.</div>
  </ng-template>
  <ng-container
    *ngIf="commentTemplateButtonsTemplate"
    [ngTemplateOutlet]="commentTemplateButtonsTemplate"
  ></ng-container>
</ng-template>

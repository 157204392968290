import { Pipe, PipeTransform } from '@angular/core';
import { FederalListingStatus } from '@transect-nx/models';

@Pipe({
  name: 'listStatusClass',
})
export class ListStatusClassPipe implements PipeTransform {
  transform(status: FederalListingStatus | undefined | null): string {
    if (!status) return 'text-shades-gray-dark';
    switch (status) {
      case FederalListingStatus.ENDANGERED:
      case FederalListingStatus.THREATENED:
        return 'text-danger-warnings-brand-danger-dark';
      case FederalListingStatus.PROPOSED_THREATENED:
      case FederalListingStatus.PROPOSED_ENDANGERED:
      case FederalListingStatus.CANDIDATE:
        return 'text-secondary-colors-secondary-color';
      default:
        return 'text-shades-gray-dark';
    }
  }
}

import { Duration } from 'moment';
import {
  GeographiesState,
  ReportAddOnOfferingKey,
  TransectFile,
} from './models';

export enum ReportAddOnStatus {
  PENDING = 'pending',
  OUT_FOR_BID = 'out_for_bid',
  USER_REVIEW_ORDER = 'user_review_order',
  BID_ACCEPTED = 'bid_accepted',
  IN_PROGRESS = 'in_progress',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  COMPLETE = 'complete',
}

export type DisplayedReportAddOn = {
  _id: string;
  created_at: Date;
  status: ReportAddOnStatus;
  owner__id: string | null;
  contractor: string | null;
  due_by: Date | null;
  completed_at: Date | null;
  price: number | null;
  option: string | null;
  order_num: number;
  invoice_sent_on: Date | null;
  sent_to_contractor_on: Date | null;
  coupon_code: string | null;
  report_add_on_offering__id: string | null;
  creator: {
    email: string | null;
    role: string | null;
  } | null;
  project: {
    _id: string | null;
    name: string | null;
    geographies_states: GeographiesState[] | null;
    customer: {
      name: string | null;
    } | null;
    report: {
      _id: string | null;
    } | null;
  } | null;
  report_add_on_offering: {
    name: string | null;
    key: ReportAddOnOfferingKey | null;
    sla: Duration | null;
  } | null;
  expert_notes: string | null;
  expert_notes_added_by_user: {
    _id: string | null;
    firstname: string | null;
    lastname: string | null;
    photo_gcs: TransectFile | null;
  } | null;
  user_has_read_comments?: boolean | null;
};

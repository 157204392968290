import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ts-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message?: string;
      template?: TemplateRef<any>;
      question?: string;
      confirmation?: string;
      cancellation?: string;
      isNegativeAction: boolean;
    },
  ) {}
}

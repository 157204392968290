import { z } from 'zod';
import { Purpose } from '@transect-nx/models';

/** Body for post / */
export const FileCreateControllerBody = z
  .object({
    originalname: z.string(),
    mimetype: z.string(),
    size: z.number(),
    purpose: z.nativeEnum(Purpose),
    relation_type: z.string().optional().nullable(),
    relation_id: z.string().optional().nullable(),
  })
  .refine((data) => {
    if (data.relation_id && !data.relation_type) {
      return false;
    }
    if (!data.relation_id && data.relation_type) {
      return false;
    }

    return true;
  }, 'Relation Id or Relation Type is missing.');

export type FileCreateControllerBody = z.infer<typeof FileCreateControllerBody>;

<h2 mat-dialog-title data-cy="confirmation-dialog">
  {{ data.question || 'Are you sure?' }}
</h2>
<mat-dialog-content class="mat-typography">
  <h3 *ngIf="data.message">{{ data.message }}</h3>
  <ng-container
    *ngIf="data.template"
    [ngTemplateOutlet]="data.template"
  ></ng-container>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button
    transect-nx-button
    buttonStyle="contained"
    [negative]="data.isNegativeAction"
    data-cy="btn-confirmation-dialog-yes"
    [mat-dialog-close]="true"
  >
    {{ data.confirmation || 'Yes' }}
  </button>
  <button
    mat-dialog-close
    data-cy="btn-confirmation-dialog-no"
    transect-nx-button
    buttonStyle="outlined"
  >
    {{ data.cancellation || 'No' }}
  </button>
</mat-dialog-actions>

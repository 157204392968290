import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { GeographyState } from '../geographies/geography-state';
import { TransectFile } from '../transect-file';
import { HabitatCharacteristics } from '@transect-nx/models';
import { FederalListingStatus } from '@transect-nx/models';

export const SpeciesObject = z.object({
  _id: z.string().uuid(),
  name_combined: z.string().nullable(),
  name_combined_html: z.string().nullable(),
  tsn: z.number().nullable(),
  name_common: z.string().nullable(),
  name_scientific: z.string().nullable(),
  population_code: z.string().nullable(),
  federal_listing_status: z.nativeEnum(FederalListingStatus).nullable(),
  geography_listing_status: z.string().nullable(),
  geography_statutorily_protected: z.boolean().nullable(),
  habitat_characteristics: z.array(z.nativeEnum(HabitatCharacteristics)),
  group: z.string().nullable(),
  geographies_states: z.array(GeographyState).optional(),
  primary_states: z.array(GeographyState).optional(),
  eo_states: z.array(GeographyState).optional(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
  review_by: DateSchema.nullable(),
  region: z.string().nullable(),
  status_expert: z.string().nullable(),
  spatial_data_sufficient: z.boolean(),
  spatial_data_sufficient_reason: z.string().nullable(),
  geography__id: z.string().uuid().nullable(),
  parent__id: z.string().uuid().nullable(),
  owner__id: z.string().uuid().nullable(),
  species_location_count: z.number().optional(),
  variation_count: z.number().optional(),
  species_code: z.string().nullable(),
  profile_image: TransectFile.partial().nullish(),
});

/** The species properties that support sorting. */
export const SortableSpeciesProperties = z.enum([
  'name_scientific',
  'name_common',
  'created_at',
  'updated_at',
  'review_by',
]);
export type SortableSpeciesProperties = z.infer<
  typeof SortableSpeciesProperties
>;

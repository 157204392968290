import { z } from 'zod';
import {
  filterModel,
  pageNumber,
  pageSize,
  sortModel,
} from '../../utils/paginated-response-rows';
import { ResponseRows } from '../../utils/response-rows';
import { stringToBoolean } from '../../utils/string-to-boolean';
import { CreateOverrideLogDTO } from '../report-override-log';
import {
  RegulationAssessmentOverrides,
  ReportPartDTO,
} from './report-part.dto';
export const ReportPartIndexQueryDTO = z.object({
  report__id: z
    .preprocess((val) => {
      if (typeof val === 'string' && val === 'null') {
        return null;
      }
      return val;
    }, z.string().uuid().nullish())
    .nullish(),
});

export type ReportPartIndexQueryDTO = z.infer<typeof ReportPartIndexQueryDTO>;

export const ReportPartIndexResponseDTO = ResponseRows(
  ReportPartDTO.pick({
    _id: true,
    type: true,
    status: true,
    progress: true,
    created_at: true,
    updated_at: true,
  }),
);

export type ReportPartIndexResponseDTO = z.infer<
  typeof ReportPartIndexQueryDTO
>;

export const ReportPartCreateBodyDTO = ReportPartDTO.pick({
  report__id: true,
  type: true,
}).required();

export type ReportPartCreateBodyDTO = z.infer<typeof ReportPartCreateBodyDTO>;

export const ReportPartLastUpdateResponseDTO = ReportPartDTO.pick({
  _id: true,
  updated_at: true,
  progress: true,
  status: true,
}).required();

export type ReportPartLastUpdateResponseDTO = z.infer<
  typeof ReportPartLastUpdateResponseDTO
>;

export const ReportPartUpdateBodyDTO = ReportPartDTO.partial()
  .omit({
    _id: true,
  })
  .extend({
    assessments: z
      .object({
        permit_overrides: z
          .record(z.string(), RegulationAssessmentOverrides)
          .nullish(),
        regulation_overrides: z
          .record(z.string(), RegulationAssessmentOverrides)
          .nullish(),
        local_permit_overrides: z
          .record(z.string(), RegulationAssessmentOverrides)
          .nullish(),
      })
      .passthrough()
      .nullish()
      .transform((val) => {
        if (val === null) {
          return {};
        }
        return val;
      }),
  });

export type ReportPartUpdateBodyDTO = z.infer<typeof ReportPartUpdateBodyDTO>;

export const ReportPartUpdateQueryDTO = z.object({
  fields: z.array(z.string()).nullish(),
});

export type ReportPartUpdateQueryDTO = z.infer<typeof ReportPartUpdateQueryDTO>;

export const ReportPartOverrideBodyDTO = z.object({
  override_logs: z.array(CreateOverrideLogDTO),
});

export type ReportPartOverrideBodyDTO = z.infer<
  typeof ReportPartOverrideBodyDTO
>;

export const GetParcelLandownerInfoFromReportPartQueryDTO = z.object({
  search: z.string().nullish(),
  sortModel: sortModel.nullish(),
  pageSize: pageSize.nullish(),
  page: pageNumber.nullish(),
  reportVersion: z.string(),
  excludeBuffer: stringToBoolean.optional(),
  filterModel: filterModel.nullish(),
});

export type GetParcelLandownerInfoFromReportPartQueryDTO = z.infer<
  typeof GetParcelLandownerInfoFromReportPartQueryDTO
>;

export const GetProblematicLCIParcelsCountQueryDTO = z.object({
  reportVersion: z.string(),
});

export type GetProblematicLCIParcelsCountQueryDTO = z.infer<
  typeof GetProblematicLCIParcelsCountQueryDTO
>;

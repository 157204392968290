import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import moment from 'moment';
import { HabitatCharacteristics, SpeciesType } from '@transect-nx/models';
import { FederalListingStatus } from '@transect-nx/models';
import { TransectFile } from '../../transect-file';

/** Body for post / */
export const SpeciesCreateControllerBody = z.object({
  tsn: z.number().optional().nullable(),
  name_common: z.string().optional().nullable(),
  name_scientific: z.string().optional().nullable(),
  federal_listing_status: z
    .nativeEnum(FederalListingStatus)
    .optional()
    .nullable(),
  geography_listing_status: z.string().optional(),
  geography_statutorily_protected: z.boolean().optional(),
  group: z.string().optional().nullable(),
  family: z.string().optional().nullable(),
  type: z.nativeEnum(SpeciesType).optional().nullable(),
  habitat_characteristics: z
    .array(z.nativeEnum(HabitatCharacteristics))
    .optional()
    .nullable(),
  description: z.string().optional().nullable(),
  commentary_regulatory: z.string().optional().nullable(),
  commentary_tips: z.string().optional().nullable(),
  population_code: z.string().optional().nullable(),
  properties: z.object({}).passthrough().optional().nullable(),
  ecos: z.object({}).passthrough().optional().nullable(),
  admin: z.object({}).passthrough().optional().nullable(),
  content: z.object({}).passthrough().optional().nullable(),
  triggers: z.array(z.object({}).passthrough()).optional().nullable(),
  seasonality: z
    .array(
      z.object({
        type: z.string(),
        notes: z.string().nullable().optional(),
        end_day: z.number(),
        end_month: z.number(),
        start_day: z.number(),
        start_month: z.number(),
      }),
    )
    .optional()
    .nullable(),
  review_by: z.preprocess(
    (val) => (val === null ? moment().add(6, 'M').toDate() : val),
    DateSchema.optional(),
  ),
  last_refresh_date: DateSchema.optional().nullable(),
  region: z.string().optional().nullable(),
  status_expert: z.preprocess(
    (val) => (val === null ? 'in_progress' : val),
    z.string().optional(),
  ),
  population_description: z.string().optional(),
  active: z.object({}).passthrough().optional(),
  temp_flag: z.string().optional(),
  refresh_threshold: z.number().optional(),
  spatial_data_sufficient: z.boolean().optional(),
  spatial_data_sufficient_reason: z.string().optional(),
  geography__id: z.string().uuid().optional().nullable(),
  parent__id: z.string().uuid().optional().nullable(),
  owner__id: z.string().uuid().optional().nullable(),
  species_code: z.string().optional().nullable(),
  profile_image: TransectFile.partial().nullish(),
});
export type SpeciesCreateControllerBody = z.infer<
  typeof SpeciesCreateControllerBody
>;

import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
  Input,
} from '@angular/core';

@Component({
  selector: '[transect-nx-menu-item]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Output() itemSelected = new EventEmitter<void>();
  @Input() disabled = false;

  private selected = false;

  constructor(private el: ElementRef<HTMLElement>) {}

  @HostBinding('attr.tabindex')
  get tabIndex() {
    return this.disabled ? -1 : 0;
  }

  @HostBinding('class.transect-nx-menu-item')
  get defaultClass() {
    return true;
  }

  @HostBinding('class.selected')
  get isSelected() {
    return this.selected;
  }

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.disabled;
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }
    this.selected = true;
    this.itemSelected.emit();
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.disabled) {
      this.selected = true;
      this.itemSelected.emit();
    }
  }

  focus(): void {
    if (!this.disabled) {
      this.el.nativeElement.focus();
    }
  }
}

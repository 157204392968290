import { z } from 'zod';
import {
  filterModel,
  pageNumber,
  pageSize,
  sortModel,
} from '../../utils/paginated-response-rows';
import { ParcelsDTO } from '../parcel-filters';
import {
  LandownerBatchDataPersonDTO,
  LandownerBatchDataPropertyOwnerNameDTO,
} from './landowner-batch-data-response';
import { LandownerContactStatus } from './landowner-contact-status-enum';

export const LandownerContactDTO = z.object({
  _id: z.string().optional(),
  order_status: z.nativeEnum(LandownerContactStatus),
  dnc: z.boolean().optional().default(false).describe(`
    True when any contact is on the Do Not Call list, Daniel's list, or TSPA Litigator list.`),
  owner_name: LandownerBatchDataPropertyOwnerNameDTO.optional(),
  phone_numbers: z.array(z.string()).optional().default([]),
  emails: z.array(z.string()).optional().default([]),
});

export type LandownerContactDTO = z.infer<typeof LandownerContactDTO>;

export const GetLandownerContactsResponseDTO = z.array(LandownerContactDTO);

export type GetLandownerContactsResponseDTO = z.infer<
  typeof GetLandownerContactsResponseDTO
>;

export const GetParcelsForLandownerContactQueryDTO = z.object({
  search: z.string().nullish(),
  sortModel: sortModel.nullish(),
  pageSize: pageSize.nullish(),
  page: pageNumber.nullish(),
  filterModel: filterModel.nullish(),
});

export type GetParcelsForLandownerContactQueryDTO = z.infer<
  typeof GetParcelsForLandownerContactQueryDTO
>;

// This DTO is used for fetching data from DB.
export const LandownerContactParcelBatchDataPersonDTO = ParcelsDTO.extend({
  landowner_contact_status: z.nativeEnum(LandownerContactStatus).nullish(),
  landowner_contact_info: LandownerBatchDataPersonDTO.nullish(),
});

export type LandownerContactParcelBatchDataPersonDTO = z.infer<
  typeof LandownerContactParcelBatchDataPersonDTO
>;

// This DTO is used when sending parcels landowner contact details to the client.
export const LandownerContactParcelDTO = ParcelsDTO.extend({
  landowner_contact: LandownerContactDTO.nullish(),
});

export type LandownerContactParcelDTO = z.infer<
  typeof LandownerContactParcelDTO
>;

export const LandownerContactWithParcelFilterParcelsResponseDTO = z
  .object({
    parcel_collection_id: z.string().uuid().nullish(),
    count: z.number(),
    parcels: z.array(LandownerContactParcelDTO),
  })
  .nullable();

export type LandownerContactWithParcelFilterParcelsResponseDTO = z.infer<
  typeof LandownerContactWithParcelFilterParcelsResponseDTO
>;

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import Session from 'supertokens-web-js/recipe/session';
import {
  AuthService,
  SecondFactorClaim,
  SecondFactorRequiredClaim,
} from '../services/auth.service';
import { SessionClaimsService } from '../services/session-claims.service';
import { TransectPlanKey } from '@transect-nx/models';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionClaimsService: SessionClaimsService,
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const stateUrl =
      this.router.getCurrentNavigation()?.extractedUrl.toString() || '';
    return this.performCheck(stateUrl, route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.performCheck(state.url, route);
  }

  private performCheck(
    stateUrl: string,
    route: ActivatedRouteSnapshot | Route,
  ): Observable<boolean | UrlTree> {
    return forkJoin({
      user: this.authService.userOrNull$.pipe(take(1)),
      isTokenSet: from(this.authService.isTokenSet()).pipe(take(1)),
      doesSessionExist: Session.doesSessionExist(),
    }).pipe(
      switchMap(({ isTokenSet, user, doesSessionExist }) => {
        if (isTokenSet && !user) {
          return this.authService.fetchCurrentUser().pipe(
            map((currentUser) => ({
              user: currentUser,
              doesSessionExist,
            })),
          );
        }

        return of({ user, doesSessionExist });
      }),
      switchMap(({ user, doesSessionExist }) =>
        forkJoin({
          user: of(user),
          payload: doesSessionExist
            ? this.authService.getTokenPayload()
            : of(null),
        }),
      ),
      switchMap(({ user, payload }) => {
        let plan: TransectPlanKey | null = null;

        if (payload) {
          plan =
            this.sessionClaimsService.TransectPlanClaim.getValueFromPayload(
              payload,
            );
        }

        if (!user && route?.data?.allowPublicUser) {
          return of(true);
        }

        if (!user) {
          this.authService.redirectUrl = this.router.parseUrl(stateUrl);
          return of(this.router.createUrlTree(['/login']));
        }

        if (plan === TransectPlanKey.NoAccess && user.role !== 'public-user') {
          return of(
            this.router.createUrlTree(['/no-access'], {
              queryParams: {
                type: 'no-company-account',
              },
            }),
          );
        }

        if (!user.verified || user.role === 'unverified') {
          return of(
            this.router.createUrlTree(['/no-access'], {
              queryParams: {
                type: 'unverified',
              },
            }),
          );
        }

        if (user.role === 'public-user' && !route?.data?.allowPublicUser) {
          return of(
            this.router.createUrlTree(['/no-access'], {
              queryParams: {
                type: 'public-user',
              },
            }),
          );
        }

        return forkJoin({
          secondFactorCompleted: Session.getClaimValue({
            claim: SecondFactorClaim,
          }),
          secondFactorRequired: Session.getClaimValue({
            claim: SecondFactorRequiredClaim,
          }),
        }).pipe(
          map(({ secondFactorCompleted, secondFactorRequired }) => {
            if (secondFactorRequired && !secondFactorCompleted) {
              this.authService.redirectUrl = this.router.parseUrl(stateUrl);

              return this.router.createUrlTree(['/login']);
            }

            return true;
          }),
        );
      }),
    );
  }
}

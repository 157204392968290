import {
  LandownerContactExportStatus,
  LandownerContactExportType,
} from '@transect-nx/models';
import { z } from 'zod';

export const CreateLandownerContactExportRequestDTO = z.object({
  export_type: z.nativeEnum(LandownerContactExportType),
  landowner_contact_order__id: z.string().uuid().nullish(),
  report__id: z.string().uuid().nullish(),
  report_part__id: z.string().uuid().nullish(),
});

export type CreateLandownerContactExportRequestDTO = z.infer<
  typeof CreateLandownerContactExportRequestDTO
>;

export const CreateLandownerContactExportResponseDTO = z.object({
  export_type: z.nativeEnum(LandownerContactExportType),
  status: z.nativeEnum(LandownerContactExportStatus),
  landowner_contact_order__id: z.string().uuid().nullable(),
});

export type CreateLandownerContactExportResponseDTO = z.infer<
  typeof CreateLandownerContactExportResponseDTO
>;

export const LandownerContactExportPubSubPayloadDTO = z.object({
  landowner_contact_export__id: z.string().uuid(),
  report_part__id: z.string().uuid().nullish(),
  is_report_v3_or_higher: z.boolean(),
});

export type LandownerContactExportPubSubPayloadDTO = z.infer<
  typeof LandownerContactExportPubSubPayloadDTO
>;

import { TransectPlanKey } from '@transect-nx/models';
import { z } from 'zod';

export const TransectPlanDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  key: z.nativeEnum(TransectPlanKey),
});
export type TransectPlanDTO = z.infer<typeof TransectPlanDTO>;

export const TransectPlansArrayDTO = TransectPlanDTO.array();
export type TransectPlansArrayDTO = z.infer<typeof TransectPlansArrayDTO>;

import { Injectable, NgZone } from '@angular/core';
import { PlatformRole } from '@transect-nx/data-transfer-objects';
import { IConfigCatClient, User } from 'configcat-common';
import * as configcat from 'configcat-js';
import { defer, from, iif, Observable, of, OperatorFunction } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export type ConfigCatUser = {
  identifier?: string | null;
  email?: string | null;
  custom?: {
    CustomerId?: string | null;
    UserRole?: PlatformRole | null;
  } | null;
};

@Injectable({
  providedIn: 'root',
})
export class ConfigCatService {
  client?: IConfigCatClient;

  shapefile: Observable<boolean>;
  miniReports: Observable<boolean>;
  oneClick: Observable<boolean>;
  parcelHeatMapsAndClusters: Observable<boolean>;
  removeAriaOwnAttribute$: Observable<boolean>;
  landownerContact$: Observable<boolean>;
  solarPulseMvtController$: Observable<boolean>;
  showPasswordResetBanner$: Observable<boolean>;
  localPermits$: Observable<boolean>;
  interconnectionImprovements$: Observable<boolean>;
  asyncParcelSummaries$: Observable<boolean>;
  projectSiteComparison$: Observable<boolean>;
  solarVelocityRevamp$: Observable<boolean>;
  pulseSentimentUiUpdate$: Observable<boolean>;

  private configCatUser: Observable<ConfigCatUser | null> =
    this.authService.userOrNull$.pipe(
      map((user) => {
        if (!user || !user._id) {
          return null;
        }

        return {
          identifier: user._id,
          email: user.email,
          custom: {
            CustomerId: user?.customers?.[0]?._id,
            UserRole: user.role,
          },
        };
      }),
    );

  private mapToConfigCatValue = <T>(
    key: string,
    defaultValue: T,
  ): OperatorFunction<ConfigCatUser | null, T> =>
    switchMap((configCatUser) => {
      return iif(
        () => Boolean(configCatUser),
        this.fetchFeatureFlagValue(key, defaultValue, configCatUser),
        of(defaultValue),
      );
    });

  constructor(private authService: AuthService, private ngZone: NgZone) {
    const logger = configcat.createConsoleLogger(3);
    const key = environment.CONFIG_CAT_KEY;

    this.ngZone.runOutsideAngular(() => {
      this.client = configcat.createClient(key, {
        logger,
      });
    });

    this.parcelHeatMapsAndClusters = this.configCatUser.pipe(
      this.mapToConfigCatValue('parcelHeatMapsAndClusters', false),
      shareReplay(1),
    );

    this.shapefile = this.configCatUser.pipe(
      this.mapToConfigCatValue('shapefile', false),
      shareReplay(1),
    );

    this.miniReports = this.configCatUser.pipe(
      this.mapToConfigCatValue('minireports', false),
      shareReplay(1),
    );

    this.oneClick = this.configCatUser.pipe(
      this.mapToConfigCatValue('oneclick', false),
      shareReplay(1),
    );

    this.removeAriaOwnAttribute$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('removeAriaOwnAttribute', false),
      shareReplay(1),
    );

    this.landownerContact$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('landownerContacts', false),
      shareReplay(1),
    );

    this.solarPulseMvtController$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('solarPulseMvt', false),
      shareReplay(1),
    );

    this.showPasswordResetBanner$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('showPasswordResetBanner', false),
      shareReplay(1),
    );

    this.localPermits$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('localPermits', false),
      shareReplay(1),
    );

    this.interconnectionImprovements$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('interconnectionImprovements', false),
      shareReplay(1),
    );

    this.asyncParcelSummaries$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('asyncParcelSummaries', false),
      shareReplay(1),
    );

    this.projectSiteComparison$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('projectSiteComparison', false),
      shareReplay(1),
    );

    this.solarVelocityRevamp$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('solarVelocityRevamp', false),
      shareReplay(1),
    );

    this.pulseSentimentUiUpdate$ = this.configCatUser.pipe(
      this.mapToConfigCatValue('pulseSentimentUiUpdate', false),
      shareReplay(1),
    );
  }

  checkPdfRefreshFlag(user: ConfigCatUser) {
    return this.fetchFeatureFlagValue('pdfrefresh', false, {
      ...user,
      identifier: user.email,
    });
  }

  checkProjectCollaborationFlag(user: ConfigCatUser) {
    return this.fetchFeatureFlagValue('projectCollaboration', false, {
      ...user,
      identifier: user.email,
    });
  }

  checkEditProjectAoi(user: ConfigCatUser) {
    return this.fetchFeatureFlagValue('editProjectAoi', false, {
      ...user,
      identifier: user.email,
    });
  }

  checkFeatureFlagForUser(
    flagName: string,
    user: ConfigCatUser,
  ): Observable<boolean> {
    return this.fetchFeatureFlagValue<boolean>(flagName, false, user);
  }

  /**
   * This method fetches the actual value of the feature flag from Config Cat
   * @param key
   * @param defaultValue
   */
  private fetchFeatureFlagValue<T>(
    key: string,
    defaultValue: T,
    configCatUser?: ConfigCatUser | null,
  ): Observable<T> {
    const identifier = configCatUser?.identifier;

    return defer(() =>
      from(
        (this.client?.getValueAsync(
          key,
          defaultValue,
          configCatUser && identifier
            ? {
                identifier,
                email: configCatUser.email ?? undefined,
                custom: (configCatUser?.custom ?? undefined) as User['custom'],
              }
            : undefined,
        ) as unknown as Observable<T>) ?? Promise.resolve(defaultValue),
      ),
    );
  }
}

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ReportSectionKeys } from '@transect-nx/models';

export const ReportSectionNoteDTO = z.object({
  _id: z.string().uuid(),
  report__id: z.string().uuid(),
  creator__id: z.string().uuid(),
  report_section_key: z.nativeEnum(ReportSectionKeys),
  note_content: z.string().min(1),
  last_edited_on: z.date(),
  updated_at: DateSchema,
});

export const CreateReportSectionNoteRequestDTO = z.object({
  report__id: z.string().uuid(),
  report_section_key: z.nativeEnum(ReportSectionKeys),
  note_content: z.string().min(1),
});

export const UpdateReportSectionNoteRequestDTO = z.object({
  report__id: z.string().uuid(),
  note_content: z.string().min(1),
  last_updated_at: DateSchema,
});

export const GetReportSectionNoteResponseDTO = z
  .object({
    _id: z.string().uuid(),
    note_content: z.string().min(1),
    last_edited_on: z.date(),
    report__id: z.string().uuid(),
  })
  .nullable();

export const GetReportAllSectionNotesResponseDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    report__id: z.string().uuid(),
    note_content: z.string().min(1),
    last_edited_on: z.date(),
    report_section_key: z.nativeEnum(ReportSectionKeys),
    updated_at: DateSchema,
  }),
);

export type ReportSectionNoteDTO = z.infer<typeof ReportSectionNoteDTO>;

export type CreateReportSectionNoteRequestDTO = z.infer<
  typeof CreateReportSectionNoteRequestDTO
>;

export type UpdateReportSectionNoteRequestDTO = z.infer<
  typeof UpdateReportSectionNoteRequestDTO
>;

export type GetReportSectionNoteResponseDTO = z.infer<
  typeof GetReportSectionNoteResponseDTO
>;

export type GetReportAllSectionNotesResponseDTO = z.infer<
  typeof GetReportAllSectionNotesResponseDTO
>;

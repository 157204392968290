import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { SolarRegulationType } from '@transect-nx/models';

export const SolarRegulationsIndexParams = z.object({
  sortModel: z
    .preprocess(
      (val) => (typeof val === 'string' ? JSON.parse(val) : val),
      sortModel,
    )
    .optional(),
  pageSize: pageSize,
  page: pageNumber,
  search: z.string().nullish(),
  regulationType: z.nativeEnum(SolarRegulationType),
  filterModel: z
    .preprocess(
      (val) => {
        if (typeof val === 'string' && val.trim() === '') {
          return [];
        }
        return typeof val === 'string' ? JSON.parse(val) : val;
      },
      z.array(
        z.object({
          key: z.string(),
          values: z.union([z.array(z.string()), z.string()]).optional(),
        }),
      ),
    )
    .optional(),
});
export type SolarRegulationsIndexParams = z.infer<
  typeof SolarRegulationsIndexParams
>;

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import {
  CatalogItemRegion,
  CatalogItemStatusExpert,
} from '@transect-nx/models';

export const CatalogItem = z.object({
  _id: z.string().uuid().optional().nullable(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  key: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  category: z.string().optional().nullable(),
  preview: z.object({}).passthrough().optional().nullable(),
  properties: z.object({}).passthrough().optional().nullable(),
  anonymize: z.boolean().optional().nullable(),
  admin: z.object({}).passthrough().optional().nullable(),
  active: z.boolean().optional().nullable(),
  beta: z.boolean().default(false).optional(),
  public: z.boolean().optional().nullable(),
  schema: z.object({}).passthrough().optional().nullable(),
  style_definition: z.object({}).passthrough().optional().nullable(),
  customer__id: z.string().uuid().optional().nullable(),
  context: z.object({}).passthrough().optional().nullable(),
  review_by: DateSchema.optional().nullable(),
  new_until_date: DateSchema.optional().nullable(),
  free_layer: z.boolean().optional().nullable(),
  updater__id: z.string().uuid().optional().nullable(),
  region: z.nativeEnum(CatalogItemRegion).optional().nullable(),
  status_expert: z.nativeEnum(CatalogItemStatusExpert).optional().nullable(),
  user_selected_catalog_items: z.any().nullish(),
  center_point: z
    .object({
      latitude: z.number().nullable(),
      longitude: z.number().nullable(),
      zoomLevel: z.number().nullable(),
    })
    .nullable(),
  catalog_group: z
    .object({
      _id: z.string().uuid(),
      name: z.string().optional(),
      layer_manager_label: z.string().nullable(),
      featured: z.boolean().optional().nullable(),
    })
    .optional()
    .nullable(),
  filters: z
    .record(
      z.object({
        label: z.string(),
        type: z.string(),
        operations: z.any(),
      }),
    )
    .nullable(),
});

export type CatalogItem = z.infer<typeof CatalogItem>;

import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppName, getVersionHeader } from '@transect-nx/shared/version';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import { ForcedUpdateDialogService } from '../modules/forced-update-dialog/services/forced-update-dialog.service';
import { CheckForUpdateService } from './check-for-update.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(
    private forcedUpdateDialogService: ForcedUpdateDialogService,
    private checkForUpdateService: CheckForUpdateService,
    private http: HttpClient,
  ) {
    const { value } = getVersionHeader('backend');
    // eslint-disable-next-line no-console
    console.log(`Current version ${value}`);
  }

  private getApiVersion() {
    return this.http.get<{ version: string }>(environment.apiUrl);
  }

  checkBackendApiForceUpdate() {
    return this.getApiVersion().pipe(
      map((data) => this.checkForceUpdate(data)),
    );
  }

  checkForceUpdate<T>(data: T) {
    if (!this.checkForUpdateService.isNewVersionAvailableValue) {
      return false;
    }

    if (
      typeof data === 'object' &&
      data !== null &&
      'version' in data &&
      typeof data['version'] === 'string'
    ) {
      const { value } = getVersionHeader('backend');
      if (value !== data.version) {
        this.forcedUpdateDialogService.open();
        return true;
      }

      return false;
    }

    if (!(data instanceof HttpResponseBase)) {
      return false;
    }

    if (this.isVersionMismatchedResponse(data)) {
      this.forcedUpdateDialogService.open();
      return true;
    }

    return false;
  }

  isVersionMismatchedResponse(response: HttpResponseBase) {
    const url = new URL(response.url ?? '');

    let appName: AppName | null = null;

    if (environment.mvtServerUrl.includes(url.host)) {
      appName = 'mvt-server';
    } else if (environment.apiUrl.includes(url.host)) {
      appName = 'backend';
    }

    if (!appName) {
      return false;
    }

    const { field, value } = getVersionHeader(appName);
    const responseVersion = response.headers.get(field);

    return responseVersion && responseVersion !== value;
  }
}

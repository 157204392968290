import { z } from 'zod';
import { FormTypeKey } from '@transect-nx/models';

export const FormTypeKeyDTO = z.nativeEnum(FormTypeKey);

export type FormTypeKeyDTO = z.infer<typeof FormTypeKeyDTO>;

export const FormTypeDTO = z.object({
  _id: z.string().uuid().optional().nullable(),
  key: FormTypeKeyDTO,
  name: z.string().nullish(),
  description: z.string().nullish(),
});

export type FormTypeDTO = z.infer<typeof FormTypeDTO>;

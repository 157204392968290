import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AssessmentLevels, FederalListingStatus } from '@transect-nx/models';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'transect-nx-species-list-item',
  templateUrl: './species-list-item.component.html',
  styleUrls: ['./species-list-item.component.scss'],
})
export class SpeciesListItemComponent implements OnInit, OnChanges {
  @Input() isOpen = false;
  @Input() commonName = 'Species Name';
  @Input() image$: Observable<string> | undefined;
  @Input() speciesGroup: string | undefined;
  @Input() assessmentLevel?: AssessmentLevels;
  @Input() federalListingStatus?: FederalListingStatus | null;
  @Input() stateListingStatuses: {
    state: string | null;
    status: FederalListingStatus;
  }[] = [];
  @Input() recommendation: string | undefined = 'lorem ipsum dolor sit amet';
  @Input() userRole: 'admin' | 'user' | 'unverified' | 'public-user' = 'user';

  iconClass: string | undefined;
  blink = false;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    this.iconClass = this.getIconClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['speciesGroup']) {
      this.iconClass = this.getIconClass();
    }
  }

  getReadableAssessmentLevel(): string {
    switch (this.assessmentLevel) {
      case 'species_of_concern':
        return 'Species of Concern';
      case 'not_likely_to_occur':
        return 'Not Likely to Occur';
      case 'may_occur':
        return 'May Occur';
      case 'likely_to_occur':
        return 'Likely to Occur';
      case 'ignore':
        return 'Ignore';
      default:
        return 'None';
    }
  }

  private getIconClass(): string {
    switch (this.speciesGroup) {
      case 'Amphibians':
      case 'Hydroids':
      case 'Crustaceans':
      case 'Sponges':
      case 'Clams':
        return 'fa-frog';
      case 'Flowering Plants':
      case 'Lichens':
      case 'Ferns and Allies':
      case 'Conifers and Cycads':
      case 'Corals':
        return 'fa-leaf';
      case 'Insects':
      case 'Millipedes':
      case 'Flatworms and Roundworms':
      case 'Annelid Worms':
      case 'Snails':
        return 'fa-bug';
      case 'Reptiles':
        return 'fa-turtle';
      case 'Fishes':
        return 'fa-fish';
      case 'Arachnids':
        return 'fa-spider';
      case 'Mammals':
        return 'fa-paw';
      case 'Birds':
        return 'fa-dove';
      default:
        return 'fa-paw';
    }
  }

  toggleOpen() {
    if (!this.recommendation) return;
    this.isOpen = !this.isOpen;
  }

  scrollIntoView() {
    if (!this.isOpen) {
      this.toggleOpen();
    }

    let headerOffset = 144;

    if (this.userRole === 'admin') {
      headerOffset = 178;
    }

    const elementPosition = (
      this.elRef.nativeElement as HTMLDivElement
    ).getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    this.blink = true;

    timer(3000).subscribe(() => {
      this.blink = false;
    });
  }
}

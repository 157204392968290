import { z } from 'zod';
import { DateAsString, DateSchema } from '../../utils/date-schema';
import { FeatureCollectionSchema } from '../../utils/geometry-object-schema';
import { safeNumber, stringToNumber } from '../../utils/string-to-number';
import { ReportPartStatus, ReportPartType } from '@transect-nx/models';

export const RegulationAssessmentOverrides = z.object({
  notes: z.string().nullish(),
  adminOverridden: z.boolean().nullish(),
  overridden_by: z.string().uuid().nullish(),
  overridden_date: DateAsString.nullish(),
  overridden_role: z.string().nullish(),
  value: z.string().nullish(),
});

export type RegulationAssessmentOverrides = z.infer<
  typeof RegulationAssessmentOverrides
>;

export const ReportPartDTO = z.object({
  _id: z.string().uuid(),
  type: z.nativeEnum(ReportPartType),
  status: z.nativeEnum(ReportPartStatus),
  progress: z.preprocess(
    (val) => stringToNumber(val as string),
    z.number().min(0).max(100),
  ),
  epoch: safeNumber.default(0),
  summary: z.any(),
  assessments: z.any(),
  data: z.any(),
  errors: z.any(),
  feature_collection: FeatureCollectionSchema.partial(),
  report__id: z.string().uuid().nullable(),
  execution_start: DateSchema.nullable(),
  execution_end: DateSchema.nullable(),
  created_at: DateSchema.nullable(),
  updated_at: DateSchema.nullable(),
  deleted_at: DateSchema.nullable(),
});

export type ReportPartDTO = z.infer<typeof ReportPartDTO>;

import { z } from 'zod';
import { GeometrySchema } from '../../utils/geometry-object-schema';
import { RelationSchema } from '../../utils/relation';
import { mikroOrmCollectionToArray } from '../../utils/mikro-orm-collection-to-array';
import { Collection } from '@mikro-orm/core';

export const StringFilterOps = z.enum([
  'Contains',
  'Does not contain',
  'Is',
  'Is not',
]);

export const ProximityParamsDTO = z.object({
  distance_to: z.number().nullable(),
  voltage: z.object({
    min_kv: z.number().nullable(),
    max_kv: z.number().nullable(),
  }),

  min_capacity: z.number().nullish(),

  min_bus_voltage: z.number().nullish(),
  constraints: z.array(
    z.object({
      type: StringFilterOps,
      value: z.string(),
    }),
  ),
});

export const OwnerParamsDTO = z.object({
  constraints: z.array(
    z.object({
      type: StringFilterOps,
      value: z.string(),
    }),
  ),
  filterOperator: z.union([z.literal('any'), z.literal('all')]).optional(),
});

export type OwnerParamsDTO = z.infer<typeof OwnerParamsDTO>;

export const BuildableAreaCriteriaParamsDTO = z.object({
  flood_100_year: z.boolean().default(false),
  flood_500_year: z.boolean().default(false),
  wetlands: z.boolean().default(false),
  waterways: z.boolean().default(false),
  slope_0_2: z.boolean().default(false),
  slope_2_4: z.boolean().default(false),
  slope_4_6: z.boolean().default(false),
  slope_6_8: z.boolean().default(false),
  slope_8_10: z.boolean().default(false),
  slope_10_12: z.boolean().default(false),
  slope_12_14: z.boolean().default(false),
  slope_14_16: z.boolean().default(false),
  slope_16_18: z.boolean().default(false),
  slope_18_20: z.boolean().default(false),
  slope_20_22: z.boolean().default(false),
  slope_22_24: z.boolean().default(false),
  slope_gte_24: z.boolean().default(false),
  aspect_north: z.boolean().default(false),
  aspect_northeast: z.boolean().default(false),
  aspect_east: z.boolean().default(false),
  aspect_southeast: z.boolean().default(false),
  aspect_south: z.boolean().default(false),
  aspect_southwest: z.boolean().default(false),
  aspect_west: z.boolean().default(false),
  aspect_northwest: z.boolean().default(false),
  land_cover_hda: z.boolean().default(false),
  land_cover_mda: z.boolean().default(false),
  land_cover_lda: z.boolean().default(false),
  land_cover_oda: z.boolean().default(false),
  land_cover_trees: z.boolean().default(false),
  all_areas_prime_farmland: z.boolean().default(false),
  farmland_statewide_importance: z.boolean().default(false),
  not_prime_farmland: z.boolean().default(false),
  prime_farmland_if_drained: z.boolean().default(false),
  prime_farmland_if_drained_protected: z.boolean().default(false),
  hydric_soils: z.boolean().default(false),
  species_critical_habitat: z.boolean().default(false),
  protected_areas_state_owned: z.boolean().default(false),
  protected_areas_federally_owned: z.boolean().default(false),
  military_bases: z.boolean().default(false),
  current_native_american_areas_national: z.boolean().default(false),
  airports: z.boolean().default(false),
  cemeteries: z.boolean().default(false),
  roads: z.boolean().default(false),
  railways: z.boolean().default(false),
  transmission_lines: z.boolean().default(false),
  substations: z.boolean().default(false),
  power_plants: z.boolean().default(false),
  nation_conservation_easement_database: z.boolean().default(false),
  wells: z.boolean().default(false),
  pipelines: z.boolean().default(false),
});

export const ParcelFilterMoreParamsDTO = z.object({
  brownfields: z.boolean().default(false),
  fossil_fuel_employment: z.boolean().default(false),
  retired_coal_plants_and_mines: z.boolean().default(false),
  protected_areas_state_owned: z.boolean().default(false),
  protected_areas_federally_owned: z.boolean().default(false),
  current_native_american_areas_national: z.boolean().default(false),
  brownfield_site: z.boolean().default(false),
  landfills_site: z.boolean().default(false),
  superfund_final_npl_site: z.boolean().default(false),
  superfund_proposed_npl_site: z.boolean().default(false),
  within_low_income_communities: z.boolean().default(false),
});

export const ParcelFilterDTO = z.object({
  name: z.string().default('Draft'),
  customer_shared: z.boolean().default(false),
  boundary: GeometrySchema.nullable(),
  search_radius: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable(),
  ),
  drawn_geometry: GeometrySchema.nullable(),
  transmission_line_params: ProximityParamsDTO.passthrough().nullable(),
  substations_params: ProximityParamsDTO.passthrough().nullable(),
  landowner_names_params: OwnerParamsDTO.passthrough().nullable(),
  parcel_address_params: OwnerParamsDTO.passthrough().nullable(),
  apn_parcel_num_params: OwnerParamsDTO.passthrough().nullable(),
  parcel_size_min: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable(),
  ),
  parcel_size_max: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable(),
  ),
  enable_buildable_layer: z.boolean().default(false),
  buildable_area_criteria_params:
    BuildableAreaCriteriaParamsDTO.passthrough().nullable(),
  more_params: ParcelFilterMoreParamsDTO.passthrough().nullable(),
  acreage_inside_parcel_min: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable(),
  ),
  percentage_of_parcel: z.preprocess(
    (val) => (val ? Number(val) : val),
    z.number().nullable(),
  ),
  draft: z.boolean().default(false),
  public: z.boolean().default(false),
  creator: z.object({
    _id: z.string().uuid(),
    fullname: z.string(),
  }),
  project: RelationSchema(
    z.object({
      _id: z.string().uuid(),
      name: z.string(),
      context: z.object({
        project_type: z.string(),
        project_subtype: z.string().optional(),
      }),
      reports: z
        .preprocess(
          (val) => mikroOrmCollectionToArray(val as Collection<object>),
          z
            .array(
              z.object({
                _id: z.string().uuid(),
                status: z.string(),
              }),
            )
            .nullish(),
        )
        .nullish(),
      public: z.boolean().nullable(),
      customer__id: z.string().uuid().nullish(),
      preferences: z.object({ visionColor: z.string().nullish() }),
      geometry: GeometrySchema,
      owner: z.string().nullable(),
    }),
  ).nullish(),
  excluded_parcel_ids: z.array(z.string()).nullish(),
});

export type ParcelFilterDTO = z.infer<typeof ParcelFilterDTO>;
export type BuildableAreaCriteriaParamsDTO = z.infer<
  typeof BuildableAreaCriteriaParamsDTO
>;
export type ParcelFilterMoreParamsDTO = z.infer<
  typeof ParcelFilterMoreParamsDTO
>;

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import defaultTheme from '@mapbox/mapbox-gl-draw/src/lib/theme.js';
import * as turf from '@turf/turf';
import { FeatureCollection } from '@turf/turf';
import {
  AnyLayer,
  AnySourceData,
  EventData,
  FilterOptions,
  GeoJSONSource,
  Layer,
  Map,
  MapEventType,
  MapLayerEventType,
  Style,
  VectorSource,
  VectorSourceImpl,
} from 'mapbox-gl';
import {
  BehaviorSubject,
  defer,
  interval,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { first, map, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { request } from 'http';

export class TransectMap extends Map {
  private destroy$ = new Subject<void>();

  public readonly styleLoaded$: Observable<void> = defer(() => {
    return this.isStyleLoaded()
      ? of(true)
      : interval(50).pipe(
          map(() => this.isStyleLoaded()),
          first((isStyleLoaded) => isStyleLoaded),
        );
  }).pipe(
    map(() => {
      return;
    }),
  );

  private _isMapLoaded$ = new BehaviorSubject<boolean>(false);
  public readonly mapLoaded$: Observable<void> = this._isMapLoaded$.pipe(
    first((isMapLoaded) => isMapLoaded),
    map(() => {
      return;
    }),
  );

  private _sources: { sourceId: string; source: AnySourceData }[] = [];
  private _layers: Layer[] = [];
  private _images: {
    [key: string]: {
      image:
        | HTMLImageElement
        | ArrayBufferView
        | {
            width: number;
            height: number;
            data: Uint8Array | Uint8ClampedArray;
          }
        | ImageData
        | ImageBitmap;
      options: { pixelRatio?: number; sdf?: boolean };
    };
  } = {};

  private _initialStyle: string | Style;

  constructor(options?: mapboxgl.MapboxOptions) {
    super({
      ...options,
      transformRequest: (url: string) => {
        if (url.startsWith(environment.mvtServerUrl)) {
          const storedMagicLink = sessionStorage.getItem('magic-link');
          const magicLink =
            new URLSearchParams(window.location.search).get('magic-link') ??
            storedMagicLink;
          const headers = magicLink ? { 'magic-link': magicLink } : {};
          return {
            url,
            credentials: 'include',
            headers: headers,
          };
        } else {
          return { url };
        }
      },
    });

    this._initialStyle = options?.style ?? this.getStyle();
    this.listenToIsStyleLoadingChanges();
  }

  private _draw$ = new BehaviorSubject<MapboxDraw>(null);
  public readonly draw$ = this._draw$.asObservable();

  get draw(): MapboxDraw {
    return this._draw$.getValue();
  }

  once$<T extends keyof MapLayerEventType>(
    type: T,
    layer: string,
  ): Observable<MapLayerEventType[T] & EventData>;
  once$<T extends keyof MapEventType>(
    type: T,
  ): Observable<MapEventType[T] & EventData>;
  once$(type: string): Observable<any>;
  once$<T extends keyof MapEventType>(type: T): Observable<any>;
  once$(type: any, layer?: any): Observable<any> {
    if (layer) {
      return new Observable((observer) => {
        this.once(type, layer, (e) => {
          observer.next(e);
          observer.complete();
        });
      });
    } else {
      return new Observable((observer) => {
        this.once(type, (e) => {
          observer.next(e);
          observer.complete();
        });
      });
    }
  }

  on$<T extends keyof MapLayerEventType>(
    type: T,
    layer: string,
  ): Observable<MapLayerEventType[T] & EventData>;
  on$<T extends keyof MapEventType>(
    type: T,
  ): Observable<MapEventType[T] & EventData>;
  on$(type: string): Observable<any>;
  on$(type: any, layer?: any): Observable<any> {
    if (layer) {
      return new Observable((observer) => {
        const listener = (e) => {
          observer.next(e);
        };
        this.on(type, layer, listener);

        return () => {
          this.off(type, layer, listener);
        };
      });
    } else {
      return new Observable((observer) => {
        const listener = (e) => {
          observer.next(e);
        };

        this.on(type, listener);

        return () => {
          this.off(type, listener);
        };
      });
    }
  }

  remove(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this._sources = [];
    this._draw$ = null;
    this._layers = [];
    this._images = {};

    super.remove();
  }

  moveLayer(layerId: string, beforeId?: string): this {
    const layer = this._layers.find((lyr) => lyr.id === layerId);

    if (this.getLayer(layerId) && layer) {
      if (beforeId && !this.getLayer(beforeId)) {
        return this;
      }

      super.moveLayer(layerId, beforeId);

      const layerIndex = this._layers.findIndex((lyr) => lyr.id === layerId);
      this._layers.splice(layerIndex, 1);

      if (beforeId != null) {
        const beforeIndex = this._layers.findIndex(
          (lyr) => lyr.id === beforeId,
        );
        this._layers.splice(beforeIndex, 0, layer);
      } else {
        this._layers.push(layer);
      }
    }

    return this;
  }

  loadImage$(url: string): Observable<HTMLImageElement | ImageBitmap> {
    return new Observable((observer) => {
      super.loadImage(url, (error, result) => {
        if (error) {
          observer.error(error);
        }

        observer.next(result);
        observer.complete();
      });
    });
  }

  addImage(
    name: string,
    image:
      | HTMLImageElement
      | ArrayBufferView
      | { width: number; height: number; data: Uint8Array | Uint8ClampedArray }
      | ImageData
      | ImageBitmap,
    options?: { pixelRatio?: number; sdf?: boolean },
  ): void {
    if (!this.hasImage(name)) {
      super.addImage(name, image, options);
    }

    this._images[name] = { image, options };
  }

  removeImage(name: string): void {
    super.removeImage(name);
    delete this._images[name];
  }

  setFilter(
    layerId: string,
    filterExpression?: boolean | any[],
    options?: FilterOptions,
  ): this {
    if (this.getLayer(layerId)) {
      super.setFilter(layerId, filterExpression, options);

      const layer = this._layers.find((lyr) => lyr.id === layerId);

      if (typeof filterExpression === 'boolean') {
        if (filterExpression) {
          delete layer.filter;
        } else {
          layer.filter = [];
        }
      } else if (filterExpression === null || filterExpression === undefined) {
        delete layer.filter;
      } else {
        layer.filter = filterExpression;
      }
    }

    return this;
  }

  setPaintProperty(
    layerId: string,
    name: string,
    value: any,
    klass?: string,
  ): this {
    if (this.getLayer(layerId)) {
      super.setPaintProperty(layerId, name, value, klass);

      const layer: Layer = this._layers.find((lyr) => lyr.id === layerId);

      if (!layer.paint) {
        layer.paint = {};
      }

      layer.paint[name] = value;
    }

    return this;
  }

  setSourceData(
    sourceId: string,
    data:
      | GeoJSON.Feature<GeoJSON.Geometry>
      | GeoJSON.FeatureCollection<GeoJSON.Geometry>
      | turf.FeatureCollection
      | string
      | VectorSource,
  ): void {
    const source = this.getSource(sourceId);

    if (!source) {
      return;
    }

    if (source.type === 'geojson' || source.type === 'vector') {
      const thisMapSource = this._sources.find((s) => s.sourceId === sourceId);

      if (thisMapSource.source.type === 'geojson') {
        thisMapSource.source = {
          type: 'geojson',
          data: data as any,
        };
        (source as GeoJSONSource).setData(data as any);
      } else if (thisMapSource.source.type === 'vector') {
        const vectorSourceData = data as VectorSource;
        thisMapSource.source = { ...(data as VectorSource) };
        (source as VectorSourceImpl).setTiles(vectorSourceData.tiles);
      }
    } else {
      throw new Error('Can only set source data for a geojson source.');
    }
  }

  setLayoutProperty(layerId: string, name: string, value: any): this {
    if (this.getLayer(layerId)) {
      super.setLayoutProperty(layerId, name, value);

      const layer: Layer = this._layers.find((lyr) => lyr.id === layerId);

      if (!layer.layout) {
        layer.layout = {};
      }

      layer.layout[name] = value;
    }

    return this;
  }

  setLayoutPropertyBySourceId(
    sourceId: string,
    name: string,
    value: any,
  ): this {
    if (this.getSource(sourceId)) {
      const sourceLayers = this.getStyle().layers.filter(
        (layer: Layer) => layer.source === sourceId,
      );
      sourceLayers.forEach((l) => {
        super.setLayoutProperty(l.id, name, value);

        const layer: Layer = this._layers.find((lyr) => lyr.id === l.id);

        if (!layer.layout) {
          layer.layout = {};
        }

        layer.layout[name] = value;
      });
    }

    return this;
  }

  addDrawControl(
    options?: any,
    position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left',
  ): MapboxDraw {
    const QuickSelectItemsMode = {
      onSetup(): any {
        const state = {};
        return state;
      },
      onClick(state, e): void {
        const mouseEvent: MouseEvent = e.originalEvent;
        const transectMap: TransectMap = e.target;
        const drawnFeatures: FeatureCollection = transectMap.draw.getAll();
        const matchingFeature = drawnFeatures?.features?.find(
          (feature) => e.featureTarget?.properties?.id === feature.id,
        );

        if (mouseEvent.button === 0 && matchingFeature) {
          const propertiesString = JSON.stringify(matchingFeature.properties);
          const featuresToDelete = drawnFeatures.features.filter(
            (feature) =>
              matchingFeature.id === feature.id ||
              propertiesString === JSON.stringify(feature.properties),
          );

          for (const featureToDelete of featuresToDelete) {
            this.deleteFeature(featureToDelete.id);
          }
        }
      },
      toDisplayFeatures(state, geojson, display): void {
        display(geojson);
      },
    };

    const quickSelectOptions = {
      modes: Object.assign(
        {
          quick_select_items: QuickSelectItemsMode,
        },
        MapboxDraw.modes,
      ),
      styles: [
        ...defaultTheme.filter(
          (v) => !['gl-draw-polygon-fill-inactive'].includes(v.id),
        ),
        {
          id: 'gl-draw-polygon-fill-inactive',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'fill-color': '#03fcc2',
            'fill-outline-color': '#03fcc2',
            'fill-opacity': 0.2,
          },
        },
        {
          id: 'quick-select-item-fill',
          type: 'fill',
          filter: [
            'all',
            ['==', 'mode', 'quick_select_items'],
            ['!=', '$type', 'LineString'],
            ['!=', '$type', 'Point'],
          ],
          paint: {
            'fill-color': '#00F819',
            'fill-outline-color': '#00F819',
            'fill-opacity': 0.1,
          },
        },
        {
          id: 'quick-select-outline',
          type: 'line',
          filter: ['all', ['==', 'mode', 'quick_select_items']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#00F819',
            'line-width': 3,
          },
        },
      ],
    };

    this._draw$.next(
      new MapboxDraw({
        ...options,
        ...quickSelectOptions,
      }),
    );

    super.addControl(this._draw$.getValue(), position);
    return this._draw$.getValue();
  }

  clearAllSourcesAndLayers(): void {
    for (const source of this._sources) {
      this.removeSource(source.sourceId);
    }
  }

  setStyle(
    style: string | Style,
    options?: { diff?: boolean; localIdeographFontFamily?: string },
  ): this {
    super.setStyle(style, options);

    this.restoreSourcesAndLayers().subscribe();

    return this;
  }

  removeLayer(id: string): this {
    if (this.getLayer(id)) {
      super.removeLayer(id);

      const layerIndex = this._layers.findIndex((layer) => layer.id === id);
      if (layerIndex >= 0) {
        this._layers.splice(layerIndex, 1);
      }
    }

    return this;
  }

  getSourceLayers(sourceId: string) {
    if (!this.getSource(sourceId) || !this.isSourceLoaded(sourceId)) {
      return [];
    }

    return this.getStyle().layers.filter(
      (layer: Layer) => layer.source === sourceId,
    );
  }

  areSourceLayersVisible(sourceId: string): boolean {
    if (!this.getSource(sourceId) || !this.isSourceLoaded(sourceId)) {
      return false;
    }

    const sourceLayers = this.getStyle().layers.filter(
      (layer: Layer) => layer.source === sourceId,
    );

    const areVisible = sourceLayers.reduce((acc, layer) => {
      const visibility = this.getLayoutProperty(layer.id, 'visibility');
      return acc && (visibility === 'visible' || visibility === undefined);
    }, true);

    return sourceLayers.length > 0 && areVisible;
  }

  removeSource(id: string): this {
    if (this.getSource(id)) {
      const sourceLayers = this.getStyle().layers.filter(
        (layer: Layer) => layer.source === id,
      );
      sourceLayers.forEach((layer) => this.removeLayer(layer.id));

      super.removeSource(id);

      const sourceIndex = this._sources.findIndex(
        (source) => source.sourceId === id,
      );
      if (sourceIndex >= 0) {
        this._sources.splice(sourceIndex, 1);
      }
    }

    return this;
  }

  addSource(id: string, data: AnySourceData): this {
    if (!this.getSource(id) && data) {
      super.addSource(id, data);

      const existingSourceIndex = this._sources.findIndex(
        (src) => src.sourceId === id,
      );

      if (existingSourceIndex >= 0) {
        return this;
      }

      this._sources.push({ sourceId: id, source: data });
    }

    return this;
  }

  replaceSource(id: string, data: AnySourceData): this {
    if (this.getSource(id)) {
      this.removeSource(id);
    }
    this.addSource(id, data);
    return this;
  }

  addSource$(id: string, data: AnySourceData): Observable<this> {
    return this.styleLoaded$.pipe(
      map(() => this.addSource(id, data)),
      take(1),
    );
  }

  addLayer(layer: AnyLayer | Layer, before?: string): this {
    if (this.getLayer(layer.id)) {
      return this;
    }

    let beforeLayerId = before;

    if (!this.getLayer(beforeLayerId)) {
      beforeLayerId = null;
    }

    super.addLayer(layer as AnyLayer, beforeLayerId);

    const existingLayerIndex = this._layers.findIndex(
      (lyr) => lyr.id === layer.id,
    );
    if (existingLayerIndex >= 0) {
      return this;
    }

    if (beforeLayerId) {
      const beforeIndex = this._layers.findIndex(
        (lyr) => lyr.id === beforeLayerId,
      );
      this._layers.splice(beforeIndex, 0, layer);
    } else {
      this._layers.push(layer);
    }

    return this;
  }

  replaceLayer(layer: AnyLayer | Layer, before?: string): this {
    if (this.getLayer(layer.id)) {
      this.removeLayer(layer.id);
    }
    this.addLayer(layer, before);
    return this;
  }

  addLayer$(layer: AnyLayer | Layer, before?: string): Observable<this> {
    return this.styleLoaded$.pipe(
      map(() => this.addLayer(layer, before)),
      take(1),
    );
  }

  /**
   *   This is a convenience layer so when the catalog
   *   items are populated you can pick whether they
   *   appear above or below this layer
   */
  addZIndexLayers(count: number = 1): void {
    this.addSource('empty', {
      type: 'geojson',
      data: turf.featureCollection([]) as any,
    });

    for (let i = 1; i <= count; i++) {
      this.addLayer({
        id: 'z-index-' + i,
        source: 'empty',
        type: 'fill',
      });
    }
  }

  sortLayers(): void {
    const glDrawLayers = this._layers
      .filter(
        (lyr) =>
          lyr.id.includes('gl-draw') ||
          ('source' in lyr &&
            typeof lyr.source === 'string' &&
            lyr.source.includes('gl-draw')),
      )
      .slice();

    const tsProjectLayers = this._layers
      .filter(
        (lyr) =>
          lyr.id.includes('drawn-project-layer') ||
          ('source' in lyr &&
            typeof lyr.source === 'string' &&
            lyr.source.includes('drawn-project-layer')),
      )
      .slice();

    const sortedLayers = [...tsProjectLayers, ...glDrawLayers];

    sortedLayers.forEach((lyr) => {
      this.moveLayer(lyr.id);
    });
  }

  private restoreSourcesAndLayers(): Observable<void> {
    if (!this.styleLoaded$) {
      return of(null);
    }

    return this.styleLoaded$.pipe(
      take(1),
      tap(() => {
        Object.entries(this._images).forEach(([name, obj]) => {
          this.addImage(name, obj.image, obj.options);
        });

        if (this._sources?.length > 0) {
          for (const source of this._sources) {
            if (!this.getSource(source.sourceId)) {
              try {
                super.addSource(source.sourceId, source.source);
              } catch (error) {
                this.removeSource(source.sourceId);
              }
            }
          }

          this._layers.forEach((layer: Layer, index) => {
            if (
              typeof layer.source === 'string' &&
              !this.getSource(layer.source)
            ) {
              this.removeLayer(layer.id);
            } else if (!this.getLayer(layer.id)) {
              try {
                Object.keys(layer)
                  .filter(
                    (key) => layer[key] === null || layer[key] === undefined,
                  )
                  .forEach((key) => {
                    delete layer[key];
                  });
                super.addLayer(layer as AnyLayer);
              } catch (error) {
                this.removeLayer(layer.id);
              }
            }
          });

          // Bring all mapbox draw layers to the top
          this.sortLayers();
        }
      }),
      takeUntil(this.destroy$),
    );
  }

  private listenToIsStyleLoadingChanges(): void {
    this.once$('load')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this._isMapLoaded$.next(true);
      });
  }

  public get initialStyle(): string | Style {
    return this._initialStyle;
  }

  public toggleVisibility(layerIds: string[], on: boolean) {
    if (layerIds?.length) {
      layerIds.forEach((layerId) => {
        this.setLayoutProperty(layerId, 'visibility', on ? 'visible' : 'none');
      });
    }
  }
}

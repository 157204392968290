import { z } from 'zod';

import {
  DateFilter,
  SetFilterBase,
  TextFilter,
} from '../../utils/aggrid-filters';
import { DateSchema } from '../../utils/date-schema';
import { jsonSchema } from '../../utils/string-to-json';
import { safeNumber } from '../../utils/string-to-number';
import { TimelineUnit } from '@transect-nx/models';

export const PermitsIndexQuery = z
  .object({
    permit__ids: z
      .union([jsonSchema(z.array(z.string().uuid())), z.string().uuid()])
      .nullish(),
  })
  .nullish();

export type PermitsIndexQuery = z.infer<typeof PermitsIndexQuery>;

export const PermitSetFilter = SetFilterBase.extend({
  values: z.array(z.string()).nullish(),
});

export type PermitSetFilter = z.infer<typeof PermitSetFilter>;

export const PermitsSearchBody = z.object({
  params: z.object({
    general: z.string().nullish(),
    page: safeNumber.nullish(),
    pageSize: safeNumber.nullish(),
    filterModel: jsonSchema(
      z.object({
        'customer.name': TextFilter.nullish(),
        'geography._id': PermitSetFilter.nullish(),
        status_expert: PermitSetFilter.nullish(),
        region: PermitSetFilter.nullish(),
        owner__id: PermitSetFilter.nullish(),
        active: PermitSetFilter.nullish(),
        created_at: DateFilter.nullish(),
        updated_at: DateFilter.nullish(),
        review_by: DateFilter.nullish(),
      }),
    ).nullish(),
    sortModel: jsonSchema(
      z.array(
        z.object({
          sort: z.enum(['asc', 'desc', 'ASC', 'DESC']),
          colId: z.string(),
        }),
      ),
    ).nullish(),
  }),
});

export type PermitsSearchBody = z.infer<typeof PermitsSearchBody>;

export enum PermitStatusExpert {
  IN_REVIEW = 'in_review',
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
}

export const PermitDTO = z.object({
  _id: z.string().uuid(),
  descriptor: z.string().nullish(),
  name: z.string().nullish(),
  acronym: z.string().nullish(),
  content: z
    .object({
      timeline: z.string().nullish(),
      timeline_min: z.number().nullish(),
      timeline_max: z.number().nullish(),
      timeline_unit: z.nativeEnum(TimelineUnit).nullish(),
      recommendation: z.string().nullish(),
      enabled_language: z.string().nullish(),
    })
    .nullish(),
  jurisdiction: z.string().nullish(),
  admin: z.any().nullish(),
  links: z
    .array(
      z.object({
        name: z.string(),
        url: z.string().nullish(),
      }),
    )
    .nullish(),
  active: z.boolean().nullish(),
  geography__id: z.string().nullish(),
  context: z.any().nullish(),
  triggers: z.array(z.any()).nullish(),
  triggers2: z.array(z.any()).nullish(),
  status_expert: z.nativeEnum(PermitStatusExpert).nullish(),
  refresh_threshold: z.number().nullish().default(90),
  last_refresh_date: DateSchema.nullish(),
  requires_impact_to_waters: z.boolean().nullish().default(false),
  data_gap: z.boolean().nullish().default(false),
  review_by: DateSchema.nullish(),
  assigned_to: z.string().nullish(),
  updater__id: z.string().nullish(),
  customer__id: z.string().nullish(),
  owner__id: z.string().nullish(),
  creator__id: z.string().nullish(),
  region: z.string().nullish(),
  w__id: z.string().nullish(),
});

export const CreatePermitBody = PermitDTO.extend({
  name: z.string(),
}).omit({
  _id: true,
});

export type CreatePermitBody = z.infer<typeof CreatePermitBody>;

export const UpdatePermitBody = PermitDTO.omit({
  _id: true,
  customer__id: true,
}).deepPartial();

export type UpdatePermitBody = z.infer<typeof UpdatePermitBody>;

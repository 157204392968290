import { z } from 'zod';
import { CommentObjectType } from '@transect-nx/models';

export const CommentsIndexParams = z.object({
  object__type: z.nativeEnum(CommentObjectType),
  object__id: z.string().uuid().optional(),
  order: z.enum(['asc', 'desc']),
  page: z
    .string()
    .default('1')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `'page must be an integer`,
        });
      }
      return parsedAsInt;
    }),
  pageSize: z
    .string()
    .default('100')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `'pageSize' must be an integer`,
        });
      }
      return parsedAsInt;
    }),
  sortBy: z.string().default('created_at'),
});
export type CommentsIndexParams = z.infer<typeof CommentsIndexParams>;

import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  HostBinding,
  HostListener,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: '[transect-nx-menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(MenuItemComponent) menuItems!: QueryList<MenuItemComponent>;
  private keyManager!: FocusKeyManager<MenuItemComponent>;

  ngAfterViewInit() {
    this.keyManager = new FocusKeyManager(this.menuItems).withWrap();
    this.keyManager.setFirstItemActive();
  }

  ngOnDestroy() {
    this.keyManager.change.unsubscribe();
  }

  @HostBinding('attr.tabindex')
  get tabIndex() {
    return 0;
  }

  @HostBinding('class.transect-nx-menu')
  get defaultClass() {
    return true;
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    this.keyManager.onKeydown(event);
  }
}

import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import {
  AssessmentLevels,
  MonitoringDataPointCategory,
  MonitoringDataPointKey,
  PlannedCoalPlantRetirementValue,
  ReportMonitoredDataPointChangelogMap,
} from '@transect-nx/models';

const BaseLog = z.object({
  calculated_at: DateSchema,
  category: z.nativeEnum(MonitoringDataPointCategory),
  key: z.nativeEnum(MonitoringDataPointKey),
  log: z.object({
    value: z.object({}),
    changes: z.object({}).nullable(),
  }),
});

const ChangelogMap: {
  [key in MonitoringDataPointKey]: z.ZodType<
    ReportMonitoredDataPointChangelogMap[key]
  >;
} = {
  [MonitoringDataPointKey.PERMIT_ADDED_REMOVED]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PERMIT_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        active_permits: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permits_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            }),
          ),
          permits_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.PERMIT_REGULATIONS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PERMIT_REGULATIONS),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        permit_regulations: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            regulations: z.array(
              z.object({
                _id: z.string().uuid(),
                name: z.string(),
              }),
            ),
          }),
        ),
      }),
      changes: z
        .object({
          permit_regulations: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              regulations_added: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                }),
              ),
              regulations_removed: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                }),
              ),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_REGULATIONS]
  >,
  [MonitoringDataPointKey.PERMIT_TIMELINE]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.PERMIT_TIMELINE),
    category: z.literal(MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS),
    log: z.object({
      value: z.object({
        permit_timelines: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            timeline: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permit_timelines_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              new_timeline: z.string(),
              old_timeline: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PERMIT_TIMELINE]
  >,
  [MonitoringDataPointKey.REGULATION_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.REGULATION_ADDED_REMOVED),
    category: z.literal(
      MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
    ),
    log: z.object({
      value: z.object({
        active_regulations: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          regulations_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            }),
          ),
          regulations_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REGULATION_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.REGULATION_PERMITS]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.REGULATION_PERMITS),
    category: z.literal(
      MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
    ),
    log: z.object({
      value: z.object({
        regulation_permits: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            jurisdiction: z.string(),
            permits: z.array(
              z.object({
                _id: z.string().uuid(),
                name: z.string(),
              }),
            ),
          }),
        ),
      }),
      changes: z
        .object({
          regulation_permits: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              jurisdiction: z.string(),
              permits_added: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                }),
              ),
              permits_removed: z.array(
                z.object({
                  _id: z.string().uuid(),
                  name: z.string(),
                }),
              ),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REGULATION_PERMITS]
  >,
  [MonitoringDataPointKey.SPECIES_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.SPECIES_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          species_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            }),
          ),
          species_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.SPECIES_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          species_added: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            }),
          ),
          species_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.SPECIES_CONCERN_LEVEL),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            assessment: z.nativeEnum(AssessmentLevels),
          }),
        ),
      }),
      changes: z
        .object({
          species_concern_levels_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              new_assessment: z.nativeEnum(AssessmentLevels),
              old_assessment: z.nativeEnum(AssessmentLevels),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]
  >,
  [MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]: BaseLog.extend({
    key: z.literal(MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL),
    category: z.literal(MonitoringDataPointCategory.SPECIES_DATA),
    log: z.object({
      value: z.object({
        species: z.array(
          z.object({
            _id: z.string().uuid(),
            name: z.string(),
            assessment: z.nativeEnum(AssessmentLevels),
          }),
        ),
      }),
      changes: z
        .object({
          species_concern_levels_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              name: z.string(),
              new_assessment: z.nativeEnum(AssessmentLevels),
              old_assessment: z.nativeEnum(AssessmentLevels),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]
  >,
  [MonitoringDataPointKey.REPORT_GENERATED_AT]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.REPORT_GENERATED_AT),
    category: z.literal(MonitoringDataPointCategory.REPORT_STATS),
    log: z.object({
      value: z.object({
        generated_at: z.string(),
      }),
      changes: z
        .object({
          old_generated_at: z.string(),
          new_generated_at: z.string(),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.REPORT_GENERATED_AT]
  >,
  [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS),
    log: z.object({
      value: z.object({
        active_permits: z.array(
          z.object({
            _id: z.string().uuid(),
            title: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permits_added: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
            }),
          ),
          permits_removed: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS),
    category: z.literal(MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS),
    log: z.object({
      value: z.object({
        permit_size_limits: z.array(
          z.object({
            _id: z.string().uuid(),
            title: z.string(),
            size_limit: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permit_size_limits_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
              old_size_limit: z.string(),
              new_size_limit: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS]
  >,
  [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS),
    category: z.literal(MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS),
    log: z.object({
      value: z.object({
        permit_setbacks: z.array(
          z.object({
            _id: z.string().uuid(),
            title: z.string(),
            setback: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permit_setbacks_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
              old_setback: z.string(),
              new_setback: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS]
  >,
  [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS),
    category: z.literal(MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS),
    log: z.object({
      value: z.object({
        permit_zoning_regs: z.array(
          z.object({
            _id: z.string().uuid(),
            title: z.string(),
            zoning_regulations: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permit_zoning_regs_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
              old_zoning_regulations: z.string(),
              new_zoning_regulations: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS]
  >,
  [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED),
    category: z.literal(MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS),
    log: z.object({
      value: z.object({
        permits: z.array(
          z.object({
            _id: z.string().uuid(),
            title: z.string(),
            permits_needed: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          permits_changed: z.array(
            z.object({
              _id: z.string().uuid(),
              title: z.string(),
              old_permits_needed: z.string(),
              new_permits_needed: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED]
  >,
  [MonitoringDataPointKey.PARCELS_ADDED_REMOVED]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PARCELS_ADDED_REMOVED),
    category: z.literal(MonitoringDataPointCategory.PARCEL_INFO),
    log: z.object({
      value: z.object({
        parcels: z.array(
          z.object({
            parcelnumb: z.string(),
            center_area: z.string(),
            original_address: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          parcels_added: z.array(
            z.object({
              parcelnumb: z.string(),
              center_area: z.string(),
              original_address: z.string(),
            }),
          ),
          parcels_removed: z.array(
            z.object({
              parcelnumb: z.string(),
              center_area: z.string(),
              original_address: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PARCELS_ADDED_REMOVED]
  >,
  [MonitoringDataPointKey.PARCEL_OWNERS]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PARCEL_OWNERS),
    category: z.literal(MonitoringDataPointCategory.PARCEL_INFO),
    log: z.object({
      value: z.object({
        parcel_owners: z.array(
          z.object({
            parcelnumb: z.string(),
            center_area: z.string(),
            original_address: z.string(),
            owner: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          parcel_owners_changed: z.array(
            z.object({
              parcelnumb: z.string(),
              center_area: z.string(),
              original_address: z.string(),
              old_owner: z.string(),
              new_owner: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PARCEL_OWNERS]
  >,
  [MonitoringDataPointKey.PARCEL_ZONING_CODES]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PARCEL_ZONING_CODES),
    category: z.literal(MonitoringDataPointCategory.PARCEL_INFO),
    log: z.object({
      value: z.object({
        parcel_zoning_codes: z.array(
          z.object({
            parcelnumb: z.string(),
            center_area: z.string(),
            original_address: z.string(),
            zoning: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          parcel_zoning_codes_changed: z.array(
            z.object({
              parcelnumb: z.string(),
              center_area: z.string(),
              original_address: z.string(),
              old_zoning: z.string(),
              new_zoning: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PARCEL_ZONING_CODES]
  >,
  [MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION),
    category: z.literal(MonitoringDataPointCategory.PARCEL_INFO),
    log: z.object({
      value: z.object({
        parcel_zoning_descriptions: z.array(
          z.object({
            parcelnumb: z.string(),
            center_area: z.string(),
            original_address: z.string(),
            zoning_description: z.string(),
          }),
        ),
      }),
      changes: z
        .object({
          parcel_zoning_description_changed: z.array(
            z.object({
              parcelnumb: z.string(),
              center_area: z.string(),
              original_address: z.string(),
              old_zoning_description: z.string(),
              new_zoning_description: z.string(),
            }),
          ),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION]
  >,
  [MonitoringDataPointKey.COAL_CLOSURE]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.COAL_CLOSURE),
    category: z.literal(MonitoringDataPointCategory.ENERGY_COMMUNITIES),
    log: z.object({
      value: z.object({
        coal_closure_census_tract_symbols: z.array(z.string()),
      }),
      changes: z
        .object({
          coal_closure_census_tract_symbol_changed: z.object({
            old_symbols: z.array(z.string()),
            new_symbols: z.array(z.string()),
          }),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.COAL_CLOSURE]
  >,
  [MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT),
    category: z.literal(MonitoringDataPointCategory.ENERGY_COMMUNITIES),
    log: z.object({
      value: z.object({
        planned_coal_retirement: z.nativeEnum(PlannedCoalPlantRetirementValue),
      }),
      changes: z
        .object({
          planned_coal_retirement_changed: z.object({
            old_status: z.nativeEnum(PlannedCoalPlantRetirementValue),
            new_status: z.nativeEnum(PlannedCoalPlantRetirementValue),
          }),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT]
  >,
  [MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT]: z.object({
    calculated_at: DateSchema,
    key: z.literal(MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT),
    category: z.literal(MonitoringDataPointCategory.ENERGY_COMMUNITIES),
    log: z.object({
      value: z.object({
        is_in_fossil_fuel_employment_layer: z.boolean(),
      }),
      changes: z
        .object({
          fossil_fuel_employment_layer_changed: z.object({
            old_value: z.boolean(),
            new_value: z.boolean(),
          }),
        })
        .nullable(),
    }),
  }) as z.ZodType<
    ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT]
  >,
} as const;

const Changelog = z.union([
  ChangelogMap[MonitoringDataPointKey.PERMIT_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.PERMIT_TIMELINE],
  ChangelogMap[MonitoringDataPointKey.REGULATION_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.SPECIES_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.SPECIES_CONCERN_LEVEL],
  ChangelogMap[MonitoringDataPointKey.PERMIT_REGULATIONS],
  ChangelogMap[MonitoringDataPointKey.REGULATION_PERMITS],
  ChangelogMap[MonitoringDataPointKey.REPORT_GENERATED_AT],
  ChangelogMap[MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL],
  ChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS],
  ChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS],
  ChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS],
  ChangelogMap[MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED],
  ChangelogMap[MonitoringDataPointKey.PARCELS_ADDED_REMOVED],
  ChangelogMap[MonitoringDataPointKey.PARCEL_OWNERS],
  ChangelogMap[MonitoringDataPointKey.PARCEL_ZONING_CODES],
  ChangelogMap[MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION],
  ChangelogMap[MonitoringDataPointKey.COAL_CLOSURE],
  ChangelogMap[MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT],
  ChangelogMap[MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT],
]);

export type Changelog = z.infer<typeof Changelog>;

export const ChangelogsResponse = z.object({
  last_viewed_at: DateSchema.nullable(),
  changelogs: z.array(Changelog),
});

export type ChangelogsResponse = z.infer<typeof ChangelogsResponse>;

const ReportId = z.object({
  reportId: z.string().uuid(),
});

export const ChangelogsParams = ReportId;

export type ChangelogsParams = z.infer<typeof ChangelogsParams>;

export const ToggleMonitorParams = ReportId;

export type ToggleMonitorParams = z.infer<typeof ChangelogsParams>;

export const ToggleMonitorBody = z.union([
  z.object({
    monitoring_data_points: z.array(
      z.object({
        active: z.boolean(),
        key: z.nativeEnum(MonitoringDataPointKey),
      }),
    ),
  }),
  z.object({
    action: z.enum(['enable_all', 'disable_all']),
  }),
]);

export type ToggleMonitorBody = z.infer<typeof ToggleMonitorBody>;

export const GetMonitoredDataPointsParams = ReportId;

export type GetMonitoredDataPointsParams = z.infer<
  typeof GetMonitoredDataPointsParams
>;

export const GetMonitoredDataPointsResponse = z.array(
  z.object({
    key: z.nativeEnum(MonitoringDataPointKey),
    category: z.nativeEnum(MonitoringDataPointCategory),
    active: z.boolean(),
  }),
);

export type GetMonitoredDataPointsResponse = z.infer<
  typeof GetMonitoredDataPointsResponse
>;

export const ChangesSinceLastRefreshParams = ReportId;

export type ChangesSinceLastRefreshParams = z.infer<
  typeof ChangesSinceLastRefreshParams
>;

export const ChangesSinceLastRefreshResponse = z.object({
  last_refreshed_at: DateSchema.nullable(),
  change_count_since_last_refresh: z.number(),
  monitored_by: z.enum(['user', 'others', 'none']),
});

export type ChangesSinceLastRefreshResponse = z.infer<
  typeof ChangesSinceLastRefreshResponse
>;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationEntityType, NotificationType } from '@transect-nx/models';
import { PusherEvents } from '@transect-nx/data-transfer-objects';
import * as moment from 'moment';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, scan, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ResponseRows } from '../../../models/response-rows';
import { AlertService } from '../../../services/alert.service';
import { PusherService } from '../../../services/pusher.service';
import { Notification } from './../../../models/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notification$ = new Subject<Notification>();
  listenToNotifications$ = this.notification$.asObservable();

  private refreshNotificationCount$ = new BehaviorSubject<void>(null);

  constructor(
    private pusherService: PusherService,
    private http: HttpClient,
    private alertService: AlertService,
  ) {}

  initNotificationListener() {
    return this.pusherService.listenToEvents$(PusherEvents.NOTIFICATION).pipe(
      tap((notification) => {
        if (
          notification.type === NotificationType.WARN &&
          notification.entity_type !==
            NotificationEntityType.LANDOWNER_CONTACT_ORDER_FAILED
        ) {
          this.alertService.showWarn(notification, {
            dismissible: true,
            withTitle: true,
          });
        }

        this.refreshNotificationCount$.next();
        this.notification$.next({
          _id: notification._id,
          title: notification.title,
          description: notification.description,
          meta: notification.meta,
          entity_type: notification.entity_type,
          entity__id: notification.entity__id,
          type: notification.type,
          notifiable__id: notification.notifiable__id,
          notifiable_type: notification.notifiable_type,
          updated_at: moment(notification.updated_at),
          created_at: moment(notification.created_at).toDate(),
          seen: false,
        });
      }),
    );
  }

  fetchMyNotifications(params?: {
    page?: number;
    pageSize?: number;
    offset?: number;
    sortModel?: { column: string; order: 'asc' | 'desc' }[];
    showOnlyUnread?: boolean;
  }): Observable<ResponseRows<Notification>> {
    return this.http
      .get<ResponseRows<Notification>>(
        `${environment.apiUrl}/notifications/mine`,
        {
          params: {
            page: params?.page?.toString() ?? '',
            pageSize: params?.pageSize?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            sortModel: JSON.stringify(params?.sortModel) ?? '',
            showOnlyUnread: params.showOnlyUnread ?? '',
          },
        },
      )
      .pipe(
        map((response) => {
          return {
            ...response,
            rows: response.rows.map((row) => {
              return {
                ...row,
                created_at: moment(row.created_at).toDate(),
              };
            }),
          };
        }),
      );
  }

  notificationCount(filter: 'unseen' | 'all'): Observable<number> {
    return this.refreshNotificationCount$.pipe(
      switchMap(() => {
        return this.http.get<number>(
          `${environment.apiUrl}/notifications/my-count`,
          {
            params: {
              filter,
            },
          },
        );
      }),
    );
  }

  markSeen(params: {
    notificationIds?: string[];
    all?: boolean;
  }): Observable<void> {
    const body = {
      all: false,
      notificationIds: [],
    };

    if (params.all) {
      body.all = true;
    } else {
      body.notificationIds = params.notificationIds;
    }

    return this.http
      .patch<void>(`${environment.apiUrl}/notifications/mark-seen`, body)
      .pipe(
        tap(() => {
          this.refreshNotificationCount$.next();
        }),
      );
  }

  deleteNotification(notificationId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/notifications/${notificationId}`,
    );
  }

  sendNewNotification(notification: Partial<Notification>): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/notifications/`,
      notification,
    );
  }

  viewAndListenToNotifications(
    page: number,
    pageSize: number,
  ): Observable<Notification[]> {
    const existingNotifications$ = this.fetchMyNotifications({
      page,
      pageSize,
    });

    const newNotifications$ = this.listenToNotifications$;

    return merge(existingNotifications$, newNotifications$).pipe(
      map((response) => {
        if ('count' in response) {
          return response.rows;
        } else {
          return response;
        }
      }),
      scan(
        (
          accumulator: Notification[],
          data: Array<Notification> | Notification,
        ) => {
          if (Array.isArray(data)) {
            return [...data, ...accumulator];
          }
          return [...[data], ...accumulator];
        },
        [],
      ),
    );
  }
}

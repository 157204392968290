import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { UserDTO } from '../users';
import { Purpose } from '@transect-nx/models';

export const FileObject = z.object({
  _id: z.string().uuid().optional().nullable(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  status: z.string().default(''),
  gcs: z
    .object({
      file: z.string().optional().nullable(),
      bucket: z.string().optional().nullable(),
      url: z.string().optional().nullable(),
      madePublic: z.boolean().default(false),
    })
    .default({}),
  properties: z
    .object({
      size: z.number().optional().nullable(),
      purpose: z.nativeEnum(Purpose).optional().nullable(),
      mimetype: z.string().optional().nullable(),
      originalname: z.string().optional().nullable(),
    })
    .default({}),
  s3: z
    .object({
      file: z.string().optional().nullable(),
      bucket: z.string().optional().nullable(),
      response: z
        .object({
          ETag: z.string().optional().nullable(),
          ServerSideEncryption: z.string().optional().nullable(),
        })
        .default({}),
    })
    .default({}),
  relations: z
    .object({
      _id: z.string().optional().nullable(),
      type: z.string().optional().nullable(),
    })
    .default({}),
  creator__id: z.string().optional().nullable(),
  creator: UserDTO.partial().optional().nullable(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  reference__id: z.string().uuid().optional().nullable(),
});

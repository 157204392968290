import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { GeometrySchema } from '../../utils/geometry-object-schema';
import { ReportAnnotationCreationType } from '@transect-nx/models';

export const ReportAnnotationDTO = z.object({
  _id: z.string().uuid(),
  type: z.string().default(''),
  name: z.string().default(''),
  description: z.string().default(''),
  presence: z.boolean().default(true),
  report__id: z.string().nullable(),
  object__type: z.string().default(''),
  object__id: z.string().nullable(),
  active: z.boolean().default(true),
  priority: z.boolean().default(false),
  creation_type: z
    .nativeEnum(ReportAnnotationCreationType)
    .default(ReportAnnotationCreationType.USER),
  admin: z
    .object({
      is_ghost_water: z.boolean().optional(),
      is_exclusion_zone: z.boolean().optional(),
    })
    .default({})
    .optional(),
  geometry: GeometrySchema.nullable(),
  validation: z.string().nullable(),
  creator__id: z.string().nullable(),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type ReportAnnotationDTO = z.infer<typeof ReportAnnotationDTO>;

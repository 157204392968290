import { AssessmentLevels } from './models';

export enum MonitoringDataPointCategory {
  FEDERAL_AND_STATE_PERMITS = 'federal_and_state_permits',
  FEDERAL_AND_STATE_REGULATIONS = 'federal_and_state_regulations',
  LOCAL_SOLAR_PERMITS = 'local_solar_permits',
  SPECIES_DATA = 'species_data',
  REPORT_STATS = 'report_stats',
  ENERGY_COMMUNITIES = 'energy_communities',
  PARCEL_INFO = 'parcel_info',
}

export enum MonitoringDataPointKey {
  PERMIT_ADDED_REMOVED = 'permit_added_removed',
  PERMIT_TIMELINE = 'permit_timeline',
  REGULATION_ADDED_REMOVED = 'regulation_added_removed',
  SPECIES_ADDED_REMOVED = 'species_added_removed',
  SPECIES_CONCERN_LEVEL = 'species_concern_level',
  STATE_SPECIES_ADDED_REMOVED = 'state_species_added_removed',
  STATE_SPECIES_CONCERN_LEVEL = 'state_species_concern_level',
  PERMIT_REGULATIONS = 'permit_regulations',
  REGULATION_PERMITS = 'regulation_permits',
  REPORT_GENERATED_AT = 'report_generated_at',
  LOCAL_SOLAR_PERMITS_ADDED_REMOVED = 'local_solar_permits_added_removed',
  LOCAL_SOLAR_PERMITS_NEEDED = 'local_solar_permits_needed',
  LOCAL_SOLAR_PERMITS_SETBACKS = 'local_solar_permits_setbacks',
  LOCAL_SOLAR_PERMITS_ZONING_REGS = 'local_solar_permits_zoning_regs',
  LOCAL_SOLAR_PERMITS_SIZE_LIMITS = 'local_solar_permits_size_limits',
  PARCELS_ADDED_REMOVED = 'parcels_added_removed',
  PARCEL_OWNERS = 'parcel_owners',
  PARCEL_ZONING_CODES = 'parcel_zoning_codes',
  PARCELS_ZONING_DESCRIPTION = 'parcels_zoning_description',
  COAL_CLOSURE = 'coal_closure',
  PLANNED_COAL_PLANT_RETIREMENT = 'planned_coal_plant_retirement',
  FOSSIL_FUEL_EMPLOYMENT = 'fossil_fuel_employment',
}

export interface MonitoringStatus {
  active: boolean;
  user: string;
  report: string;
}

interface BaseChangelog<
  Key extends MonitoringDataPointKey,
  Category extends MonitoringDataPointCategory,
  Log extends { value: NonNullable<object>; changes: object | null },
> {
  calculated_at: Date;
  category: Category;
  key: Key;
  log: Log;
}

export type PermitAddRemoveChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_ADDED_REMOVED,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      active_permits: { _id: string; name: string; jurisdiction: string }[];
    };
    changes: {
      permits_added: { _id: string; name: string; jurisdiction: string }[];
      permits_removed: { _id: string; name: string; jurisdiction: string }[];
    } | null;
  }
>;

export type PermitRegulationsChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_REGULATIONS,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      permit_regulations: {
        _id: string;
        name: string;
        jurisdiction: string;
        regulations: {
          _id: string;
          name: string;
        }[];
      }[];
    };
    changes: {
      permit_regulations: {
        _id: string;
        name: string;
        jurisdiction: string;
        regulations_added: {
          _id: string;
          name: string;
        }[];
        regulations_removed: {
          _id: string;
          name: string;
        }[];
      }[];
    } | null;
  }
>;

export type PermitTimelineChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_TIMELINE,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      permit_timelines: {
        _id: string;
        name: string;
        jurisdiction: string;
        timeline: string;
      }[];
    };
    changes: {
      permit_timelines_changed: {
        _id: string;
        name: string;
        jurisdiction: string;
        new_timeline: string;
        old_timeline: string;
      }[];
    } | null;
  }
>;

export type RegulationAddRemoveChangelog = BaseChangelog<
  MonitoringDataPointKey.REGULATION_ADDED_REMOVED,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
  {
    value: {
      active_regulations: { _id: string; name: string; jurisdiction: string }[];
    };
    changes: {
      regulations_added: { _id: string; name: string; jurisdiction: string }[];
      regulations_removed: {
        _id: string;
        name: string;
        jurisdiction: string;
      }[];
    } | null;
  }
>;

export type RegulationPermitsChangelog = BaseChangelog<
  MonitoringDataPointKey.REGULATION_PERMITS,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
  {
    value: {
      regulation_permits: {
        _id: string;
        name: string;
        jurisdiction: string;
        permits: { _id: string; name: string }[];
      }[];
    };
    changes: {
      regulation_permits: {
        _id: string;
        name: string;
        jurisdiction: string;
        permits_added: { _id: string; name: string }[];
        permits_removed: { _id: string; name: string }[];
      }[];
    } | null;
  }
>;

export type SpeciesAddedRemovedChangelog = BaseChangelog<
  MonitoringDataPointKey.SPECIES_ADDED_REMOVED,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: { species: { _id: string; name: string }[] };
    changes: {
      species_added: { _id: string; name: string }[];
      species_removed: { _id: string; name: string }[];
    } | null;
  }
>;

export type SpeciesConcernLevelChangelog = BaseChangelog<
  MonitoringDataPointKey.SPECIES_CONCERN_LEVEL,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: {
      species: { _id: string; name: string; assessment: AssessmentLevels }[];
    };
    changes: {
      species_concern_levels_changed: {
        _id: string;
        name: string;
        new_assessment: AssessmentLevels;
        old_assessment: AssessmentLevels;
      }[];
    } | null;
  }
>;

export type StateSpeciesAddedRemovedChangelog = BaseChangelog<
  MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: { species: { _id: string; name: string }[] };
    changes: {
      species_added: { _id: string; name: string }[];
      species_removed: { _id: string; name: string }[];
    } | null;
  }
>;

export type StateSpeciesConcernLevelChangelog = BaseChangelog<
  MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: {
      species: { _id: string; name: string; assessment: AssessmentLevels }[];
    };
    changes: {
      species_concern_levels_changed: {
        _id: string;
        name: string;
        new_assessment: AssessmentLevels;
        old_assessment: AssessmentLevels;
      }[];
    } | null;
  }
>;

export type ReportGeneratedAtChangelog = BaseChangelog<
  MonitoringDataPointKey.REPORT_GENERATED_AT,
  MonitoringDataPointCategory.REPORT_STATS,
  {
    value: {
      generated_at: string;
    };
    changes: {
      old_generated_at: string;
      new_generated_at: string;
    } | null;
  }
>;

export type LocalSolarPermitAddRemoveChangelog = BaseChangelog<
  MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED,
  MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS,
  {
    value: {
      active_permits: { _id: string; title: string }[];
    };
    changes: {
      permits_added: { _id: string; title: string }[];
      permits_removed: { _id: string; title: string }[];
    } | null;
  }
>;

export type LocalSolarPermitSetbackChangelog = BaseChangelog<
  MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS,
  MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS,
  {
    value: {
      permit_setbacks: { _id: string; title: string; setback: string }[];
    };
    changes: {
      permit_setbacks_changed: {
        _id: string;
        title: string;
        old_setback: string;
        new_setback: string;
      }[];
    } | null;
  }
>;

export type LocalSolarPermitZoningRegsChangelog = BaseChangelog<
  MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS,
  MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS,
  {
    value: {
      permit_zoning_regs: {
        _id: string;
        title: string;
        zoning_regulations: string;
      }[];
    };
    changes: {
      permit_zoning_regs_changed: {
        _id: string;
        title: string;
        old_zoning_regulations: string;
        new_zoning_regulations: string;
      }[];
    } | null;
  }
>;

export type LocalSolarPermitSizeLimitsChangelog = BaseChangelog<
  MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS,
  MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS,
  {
    value: {
      permit_size_limits: {
        _id: string;
        title: string;
        size_limit: string;
      }[];
    };
    changes: {
      permit_size_limits_changed: {
        _id: string;
        title: string;
        old_size_limit: string;
        new_size_limit: string;
      }[];
    } | null;
  }
>;

export type LocalSolarPermitsNeededChangelog = BaseChangelog<
  MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED,
  MonitoringDataPointCategory.LOCAL_SOLAR_PERMITS,
  {
    value: {
      permits: {
        _id: string;
        title: string;
        permits_needed: string;
      }[];
    };
    changes: {
      permits_changed: {
        _id: string;
        title: string;
        old_permits_needed: string;
        new_permits_needed: string;
      }[];
    } | null;
  }
>;

export type ParcelsAddedRemovedChangelog = BaseChangelog<
  MonitoringDataPointKey.PARCELS_ADDED_REMOVED,
  MonitoringDataPointCategory.PARCEL_INFO,
  {
    value: {
      parcels: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
      }[];
    };
    changes: {
      parcels_added: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
      }[];
      parcels_removed: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
      }[];
    } | null;
  }
>;

export type ParcelOwnersChangelog = BaseChangelog<
  MonitoringDataPointKey.PARCEL_OWNERS,
  MonitoringDataPointCategory.PARCEL_INFO,
  {
    value: {
      parcel_owners: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        owner: string;
      }[];
    };
    changes: {
      parcel_owners_changed: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        old_owner: string;
        new_owner: string;
      }[];
    } | null;
  }
>;

export type ParcelZoningCodesChangelog = BaseChangelog<
  MonitoringDataPointKey.PARCEL_ZONING_CODES,
  MonitoringDataPointCategory.PARCEL_INFO,
  {
    value: {
      parcel_zoning_codes: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        zoning: string;
      }[];
    };
    changes: {
      parcel_zoning_codes_changed: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        old_zoning: string;
        new_zoning: string;
      }[];
    } | null;
  }
>;

export type ParcelZoningDescriptionChangelog = BaseChangelog<
  MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION,
  MonitoringDataPointCategory.PARCEL_INFO,
  {
    value: {
      parcel_zoning_descriptions: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        zoning_description: string;
      }[];
    };
    changes: {
      parcel_zoning_description_changed: {
        parcelnumb: string;
        center_area: string;
        original_address: string;
        old_zoning_description: string;
        new_zoning_description: string;
      }[];
    } | null;
  }
>;

export type CoalClosureChangelog = BaseChangelog<
  MonitoringDataPointKey.COAL_CLOSURE,
  MonitoringDataPointCategory.ENERGY_COMMUNITIES,
  {
    value: {
      coal_closure_census_tract_symbols: string[];
    };
    changes: {
      coal_closure_census_tract_symbol_changed: {
        old_symbols: string[];
        new_symbols: string[];
      };
    } | null;
  }
>;

export enum PlannedCoalPlantRetirementValue {
  PRIMARY = 'primary',
  NOT_PRIMARY = 'not-primary',
  BOTH = 'both',
  NONE = 'none',
}

export type PlannedCoalRetirementChangelog = BaseChangelog<
  MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT,
  MonitoringDataPointCategory.ENERGY_COMMUNITIES,
  {
    value: {
      planned_coal_retirement: PlannedCoalPlantRetirementValue;
    };
    changes: {
      planned_coal_retirement_changed: {
        old_status: PlannedCoalPlantRetirementValue;
        new_status: PlannedCoalPlantRetirementValue;
      };
    } | null;
  }
>;

export type FossilFuelEmploymentChangelog = BaseChangelog<
  MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT,
  MonitoringDataPointCategory.ENERGY_COMMUNITIES,
  {
    value: {
      is_in_fossil_fuel_employment_layer: boolean;
    };
    changes: {
      fossil_fuel_employment_layer_changed: {
        new_value: boolean;
        old_value: boolean;
      };
    } | null;
  }
>;

export type ReportMonitoredDataPointChangelogMap = {
  [K in MonitoringDataPointKey]: {
    [MonitoringDataPointKey.PERMIT_ADDED_REMOVED]: PermitAddRemoveChangelog;
    [MonitoringDataPointKey.PERMIT_TIMELINE]: PermitTimelineChangelog;
    [MonitoringDataPointKey.REGULATION_ADDED_REMOVED]: RegulationAddRemoveChangelog;
    [MonitoringDataPointKey.SPECIES_ADDED_REMOVED]: SpeciesAddedRemovedChangelog;
    [MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]: SpeciesConcernLevelChangelog;
    [MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]: StateSpeciesAddedRemovedChangelog;
    [MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]: StateSpeciesConcernLevelChangelog;
    [MonitoringDataPointKey.PERMIT_REGULATIONS]: PermitRegulationsChangelog;
    [MonitoringDataPointKey.REGULATION_PERMITS]: RegulationPermitsChangelog;
    [MonitoringDataPointKey.REPORT_GENERATED_AT]: ReportGeneratedAtChangelog;
    [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ADDED_REMOVED]: LocalSolarPermitAddRemoveChangelog;
    [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SETBACKS]: LocalSolarPermitSetbackChangelog;
    [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_ZONING_REGS]: LocalSolarPermitZoningRegsChangelog;
    [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_SIZE_LIMITS]: LocalSolarPermitSizeLimitsChangelog;
    [MonitoringDataPointKey.LOCAL_SOLAR_PERMITS_NEEDED]: LocalSolarPermitsNeededChangelog;
    [MonitoringDataPointKey.PARCELS_ADDED_REMOVED]: ParcelsAddedRemovedChangelog;
    [MonitoringDataPointKey.PARCEL_OWNERS]: ParcelOwnersChangelog;
    [MonitoringDataPointKey.PARCEL_ZONING_CODES]: ParcelZoningCodesChangelog;
    [MonitoringDataPointKey.PARCELS_ZONING_DESCRIPTION]: ParcelZoningDescriptionChangelog;
    [MonitoringDataPointKey.COAL_CLOSURE]: CoalClosureChangelog;
    [MonitoringDataPointKey.PLANNED_COAL_PLANT_RETIREMENT]: PlannedCoalRetirementChangelog;
    [MonitoringDataPointKey.FOSSIL_FUEL_EMPLOYMENT]: FossilFuelEmploymentChangelog;
  }[K];
};

export type ChangeLog =
  ReportMonitoredDataPointChangelogMap[MonitoringDataPointKey];

export enum UserReportMonitorCheckpointType {
  CHANGELOG_VIEW = 'changelog_view',
  NEW_UPDATES_FOUND = 'new_updates_found',
  MONTHLY_RECAP = 'monthly_recap',
}
